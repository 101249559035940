import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import {Dropdown} from "primereact/dropdown";
import {Button} from "primereact/button";
import {InputNumber} from "primereact/inputnumber";
import {Badge} from "primereact/badge";
import LangselectComponent from "../components/LangselectComponent";

const _NR_PublicorderPage = (props) => {

  const {t, i18n} = props;
  const [formData, setFormData] = useState({
    order_items: [],
    payment_method: process.env.REACT_APP_DEFAULT_PAYMENT_METHOD
  });
  const [loading, setLoading] = useState(false);
  const [menu, setMenu] = useState({});
  const [orderSpotUId, setOrderSpotUId] = useState('');
  const [orderSpotName, setOrderSpotName] = useState('');
  const [orderSpotId, setOrderSpotId] = useState();

  useEffect(() => {
    document.title = t('order') + ' - ' + process.env.REACT_APP_TITLE;
    axios.get(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "public/order-spot/" + window.App.urlVars().hash,
      {
        params: {},
        headers: {
          "Sys-name": window.App.urlVars().systemname,
          "Preferred-locale": Cookies.get("language")
        }
      })
      .then(response => {
        setOrderSpotName(response.data.data.name)
        setOrderSpotUId(response.data.data.unique_id)
        setOrderSpotId(response.data.data.id)
        setFormData({
          ...formData,
          point_of_sale: response.data.data.point_of_sale.id,

        })
        axios.get(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "public/menu/" + response.data.data.menu.unique_id,
          {
            params: {},
            headers: {
              "Sys-name": window.App.urlVars().systemname,
              "Preferred-locale": Cookies.get("language")
            }
          })
          .then(response => {
            setMenu(response.data.data)
          })
          .catch(error => {

          })
      })
      .catch(error => {

      })
  }, []);

  return (
    <div className="_NR_PublicorderPage p-component p-m-lg-4">
      {formData.order_items && formData.order_items[0] ? (
        <div id={"order_cart"} className={"bg-success p-text-light"}
             onClick={() => document.getElementById('cart')?.scrollIntoView({behavior: 'smooth'})}>
          <i className={'pi pi-shopping-cart'}></i> <Badge value={formData.order_items.length} severity="info"></Badge>
        </div>
      ) : (
        <div id={"order_cart"} className={"bg-warning"}>
          <i className={'pi pi-shopping-cart'}></i> <Badge value={formData.order_items.length} severity="info"></Badge>
        </div>
      )}
      <div style={{maxWidth: "400px"}} className={"p-mx-auto p-text-center p-mt-4"}>
        <LangselectComponent/>
      </div>
      <h3 className={"p-text-center"}>{orderSpotName}</h3>
      <h1 className={"p-text-center"}>{menu.name}</h1>
      <Panel style={{maxWidth: "400px"}} className={"p-mx-auto"}>
        {menu.categories?.map((cat, index) => {
          return (
            <div className={"p-p-5"}>
              <h2 className={"p-mt-0 p-pt-0"}>{cat.name}</h2>
              {cat.main_dishes?.map((maindish, index) => {
                let quantity = []
                quantity = formData.order_items.filter(item => item.dish === maindish.id)
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-3 p-col-align-center"} style={{
                      backgroundColor: '#f3f4f5',
                      borderRadius: '4px',
                      minHeight: '80px',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                      backgroundImage: 'url(https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2020/04/20/896ce9557dc74403ae9ca3e4db4c06fa_Hero+photo+-+Aktar+Islam+%2813%29.jpg)'
                    }}>
                    </div>
                    <div className={"p-col-9 p-col-align-center"}>
                      <h3>
                        {quantity && quantity[0] &&
                        <Badge value={quantity.length} severity="info" className={"p-mr-2"}
                               style={{position: 'relative', top: '-2px'}}></Badge>
                        }
                        {maindish.name}
                      </h3>
                      <div className={"p-grid"}>
                        <div className={"p-col-10 p-col-align-center p-text-right"}>
                          {maindish.dish_prices[0].amount} {maindish.dish_prices[0].currency}
                        </div>
                        <div className={"p-col-2 p-col-align-center p-text-right"}>
                          <Button type="button" icon={"pi pi-plus"} loading={loading}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      order_items: [
                                        ...formData.order_items,
                                        {
                                          quantity: 1,
                                          dish: maindish.id,
                                          name: maindish.name,
                                        }
                                      ]
                                    })
                                    //window.App.toastShow('success', t('success'), t('dish_successfully_added'))
                                  }}
                                  className="p-button-success"/>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}

              {cat.menu_combos?.map((menucombo, index) => {
                let quantity = []
                quantity = formData.order_items.filter(item => item.menu_combo === menucombo.id)
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-3 p-col-align-center"} style={{
                      backgroundColor: '#f3f4f5',
                      borderRadius: '4px',
                      minHeight: '80px',
                      backgroundPosition: 'center center',
                      backgroundSize: 'cover',
                      backgroundImage: 'url(https://d3h1lg3ksw6i6b.cloudfront.net/media/image/2020/04/20/896ce9557dc74403ae9ca3e4db4c06fa_Hero+photo+-+Aktar+Islam+%2813%29.jpg)'
                    }}>
                    </div>
                    <div className={"p-col-9 p-col-align-center"}>
                      <h3>
                        {quantity && quantity[0] &&
                        <Badge value={quantity.length} severity="info" className={"p-mr-2"}
                               style={{position: 'relative', top: '-2px'}}></Badge>
                        }
                        {menucombo.name}
                      </h3>
                      <div className={"p-grid"}>
                        <div className={"p-col-10 p-col-align-center p-text-right"}>
                          {menucombo.prices[0].amount} {menucombo.prices[0].currency}
                        </div>
                        <div className={"p-col-2 p-col-align-center p-text-right"}>
                          <Button type="button" icon={"pi pi-plus"} loading={loading}
                                  onClick={() => {
                                    setFormData({
                                      ...formData,
                                      order_items: [
                                        ...formData.order_items,
                                        {
                                          quantity: 1,
                                          menu_combo: menucombo.id,
                                          name: menucombo.name,
                                        }
                                      ]
                                    })
                                    menucombo.quantity = 0
                                    //window.App.toastShow('success', t('success'), t('menucombo_successfully_added'))
                                  }}
                                  className="p-button-success"/>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              })}
            </div>
          )
        })}
      </Panel>

      <div id={"cart"}>
        {formData.order_items && formData.order_items[0] &&
        <Panel style={{maxWidth: "400px"}} className={"p-mx-auto p-mt-5"}>
          <div className={"p-p-5"}>
            <h2 className={"p-mt-0 p-pt-0"}>{t('payment_method')}</h2>
            <div>
              <div>
                <Dropdown options={[
                  {
                    name: 'PAYMENT_METHOD_CARD',
                    value: 'PAYMENT_METHOD_CARD'
                  },
                  {
                    name: 'PAYMENT_METHOD_CASH',
                    value: 'PAYMENT_METHOD_CASH'
                  },
                ]}
                          optionLabel="name"
                          optionValue="value"
                          id={"payment_method"}
                          className={"w-100"}
                          emptyMessage={t('empty_message')}
                          onChange={(e) => {
                            setFormData({
                              ...formData,
                              payment_method: e.value
                            })
                          }}
                          value={formData.payment_method}
                          showClear
                />
              </div>
            </div>
            <div className={"p-mt-5"}>
              <h2 className={""}>{t('order_items')}</h2>
              {formData.order_items?.map((item, index) => {
                return (
                  <>
                    <div className={"p-grid"}>
                      <div className={"p-col-8 p-col-align-center p-text-bold"}>
                        {item.name}
                      </div>
                      <div className={"p-col-2 p-col-align-center p-text-bold p-text-right"}>
                        {item.quantity}
                      </div>
                      <div className={"p-col-2 p-col-align-center p-text-right"}>
                        <Button type="button" icon={"pi pi-trash"}
                                onClick={() => {
                                  let _order_items = formData.order_items
                                  _order_items.splice(index, 1)
                                  setFormData({
                                    ...formData,
                                    order_items: _order_items
                                  })
                                }} className="p-button-danger"/>
                      </div>
                    </div>
                  </>
                )
              })}
              <Button type="button" icon={"pi pi-check"}
                      label={t('order')}
                      loading={loading}
                      onClick={() => {
                        setLoading(true)
                        axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order",
                          formData,
                          {
                            params: {},
                            headers: {
                              "Sys-name": window.App.urlVars().systemname,
                              "Preferred-locale": Cookies.get("language")
                            }
                          })
                          .then(response => {
                            setFormData({
                              ...response.data.data,
                              order_items: [],
                              point_of_sale: response.data.data.point_of_sale.id,
                            })
                            setLoading(false)
                            window.App.toastShow('success', t('success'), response.data.message)
                          })
                          .catch(error => {
                            setLoading(false)
                            window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                          })
                      }}
                      className="p-button-success w-100 p-mt-5"/>
            </div>
          </div>
        </Panel>
        }
      </div>
    </div>
  )
}
export default withTranslation('common')(_NR_PublicorderPage);
