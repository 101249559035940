import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";

const _NR_PointofsaleShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsPointofsale && window.App.state.propsPointofsale.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale/" + window.App.state.propsPointofsale.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('pointofsale_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarPointofsaleShow: false,
                        sidebarPointofsaleForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name} ({formData.short_name})
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="emergency_email">{t('emergency_email')}</label>
              <span className="p-d-block p-text-bold">
                {formData.emergency_email}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="minimum_order_amount">{t('minimum_order_amount')}</label>
              <span className="p-d-block p-text-bold">
                {formData.minimum_order_amount}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="minimum_order_currency">{t('minimum_order_currency')}</label>
              <span className="p-d-block p-text-bold">
                {formData.minimum_order_currency}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="general_shipping_time">{t('general_shipping_time')}</label>
              <span className="p-d-block p-text-bold">
                {formData.general_shipping_time} {t('minues')}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="address">{t('address')}</label>
              <span className="p-d-block p-text-bold">
                {formData.country} {formData.zip_code} {formData.city}, {formData.street_and_number}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="operator_data">{t('operator_data')}</label>
              <span className="p-d-block p-text-bold">
                {formData.operator_data?.company_name}
              </span>
            </div>
          </div>


        </div>

      </Panel>
    </>
  )
}
export default withTranslation('common')(_NR_PointofsaleShowComponent);
