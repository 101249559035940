import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import axios from "axios"; import Cookies from "js-cookie";
import {OverlayPanel} from "primereact/overlaypanel";

import HelpComponent from "../HelpComponent";
import _FM_BerthFormComponent from "../_FM_berth/_FM_BerthFormComponent";
import moment from "moment";

const _FM_OooberthShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opb = useRef(null);

  useEffect(() => {
    if (window.App.state.propsOooberth && window.App.state.propsOooberth.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "out-of-order-berth/" + window.App.state.propsOooberth.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          window.App.setState({rerenderBerthList: false})
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.rerenderBerthList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('oooberth_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarOooberthShow: false,
                        sidebarOooberthForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.berth?.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="start_at">{t('start_at')}</label>
              <span className="p-d-block p-text-bold">
                {formData.start_at &&
                <>
                  {moment.utc(formData.start_at).local().format(t('YYYY-MM-DD'))}
                </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="end_at">{t('end_at')}</label>
              <span className="p-d-block p-text-bold">
                {formData.end_at &&
                <>
                  {moment.utc(formData.end_at).local().format(t('YYYY-MM-DD'))}
                </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="note">{t('note')}</label>
              <span className="p-d-block p-text-bold">
                {formData.note}
              </span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_FM_OooberthShowComponent);
