import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";
import moment from "moment";

const _PC_TransactionShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsTransaction && window.App.state.propsTransaction.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "transaction/" + window.App.state.propsTransaction.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('transaction_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            {/*<Button type="button" aria-label={t('edit')}*/}
            {/*        icon={"pi pi-pencil"}*/}
            {/*        onClick={() => {*/}
            {/*          window.App.setState({*/}
            {/*            sidebarTransactionShow: false,*/}
            {/*            sidebarTransactionForm: true,*/}
            {/*          })*/}
            {/*        }}*/}
            {/*        className="p-button-warning p-ml-2">*/}
            {/*</Button>*/}
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="amount">{t('amount')}</label>
              <span className="p-d-block p-text-bold">
                {formData.amount} {formData.currency}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="state">{t('state')}</label>
              <span className="p-d-block p-text-bold">
                {formData.state}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="third_party_id">{t('third_party_id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.third_party_id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="third_party_state">{t('third_party_state')}</label>
              <span className="p-d-block p-text-bold">
                {formData.third_party_state}
              </span>
            </div>
          </div>

          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="created_at">{t('created_at')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.created_at_snapshot).local().format(t('YYYY-MM-DD HH:mm:ss'))}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="updated_at">{t('updated_at')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.updated_at_snapshot).local().format(t('YYYY-MM-DD HH:mm:ss'))}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="from">{t('from')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.meta?.from).local().format(t('YYYY-MM-DD HH:mm:ss'))}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="to">{t('to')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.meta?.to).local().format(t('YYYY-MM-DD HH:mm:ss'))}
              </span>
            </div>
          </div>

          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="description">{t('description')}</label>
              <span className="p-d-block p-text-bold">
                {formData.meta?.description}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="checkout_url">{t('checkout_url')}</label>
              <span className="p-d-block p-text-bold">
                {formData.meta?.checkout_url}
              </span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_PC_TransactionShowComponent);
