import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_CategoryListComponent from "../components/_NR_category/_NR_CategoryListComponent";
import _NR_CategoryFormComponent from "../components/_NR_category/_NR_CategoryFormComponent";
import _NR_CategoryShowComponent from "../components/_NR_category/_NR_CategoryShowComponent";

const _NR_CategoryPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('category') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_CategoryPage p-component p-m-lg-4">
      <Panel>
        <_NR_CategoryListComponent/>
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarCategoryForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarCategoryForm: false,
               })}>
        <_NR_CategoryFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarCategoryShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarCategoryShow: false,
               })}>
        <_NR_CategoryShowComponent/>
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_NR_CategoryPage);
