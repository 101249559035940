import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_UnitListComponent from "../components/_FM_unit/_FM_UnitListComponent";
import _FM_UnitFormComponent from "../components/_FM_unit/_FM_UnitFormComponent";
import _FM_UnitShowComponent from "../components/_FM_unit/_FM_UnitShowComponent";

const _FM_AccomodatonPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('unit') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_UnitPage p-component p-m-lg-4">
      <Panel>
        <_FM_UnitListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarUnitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarUnitForm: false,
               })}>
        <_FM_UnitFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarUnitShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarUnitShow: false,
               })}>
        <_FM_UnitShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_AccomodatonPage);
