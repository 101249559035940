import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import {classNames} from 'primereact/utils';
import {InputNumber} from "primereact/inputnumber";
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";

const _NR_OrderFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [orderSpots, setOrderSpots] = useState([]);
  const [pointOfSales, setPointOfSales] = useState([]);
  const [dishes, setDishes] = useState([]);
  const [newDishId, setNewDishId] = useState();
  const [newDishName, setNewDishName] = useState();
  const [newDishQuantity, setNewDishQuantity] = useState();
  const [menucombos, setMenucombos] = useState([]);
  const [newMenucomboId, setNewMenucomboId] = useState();
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      // let _order_items = []
      // formData.order_items?.map((item, index) => {
      //   _order_items.push({
      //     id: item.id,
      //     quantity: item.quantity,
      //     dish: item.dish?.id
      //   })
      // })
      // setFormData({
      //   ...formData,
      //   order_items: _order_items
      // })
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOrderList: true,
            selectedOrderId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOrderForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOrderList: true,
            selectedOrderId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOrderForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  const dishName = (dishId) => {
    if (dishes && dishes[0]) {
      let dish = dishes.filter(item => item.id === dishId)
      if (dish[0] && dish[0].name) {
        return dish[0].name
      }
    }
  }

  useEffect(() => {
    if (window.App.state.propsOrder && window.App.state.propsOrder.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + window.App.state.propsOrder.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(Cookies.get('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-spot",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setOrderSpots(response.data.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setPointOfSales(response.data.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dish",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setDishes(response.data.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu-combo",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setMenucombos(response.data.data.items)
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    if (formData && formData.order_spot && formData.order_spot.id) {
      setFormData({
        ...formData,
        order_spot: formData.order_spot.id
      })
    }
    if (formData && formData.point_of_sale && formData.point_of_sale.id) {
      setFormData({
        ...formData,
        point_of_sale: formData.point_of_sale.id
      })
    }
  }, [formData])


  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('order_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="order_spot" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"order_spot"}/>
                      <label htmlFor="order_spot"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('orderspot')}</label>
                      <span className="p-d-block">
                      <Dropdown options={orderSpots}
                                optionLabel="name"
                                optionValue="id"
                                id={"order_spot"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    order_spot: e.value
                                  })
                                }}
                                value={formData.order_spot}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="point_of_sale" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"point_of_sale"}/>
                      <label htmlFor="point_of_sale"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('pointofsale')}</label>
                      <span className="p-d-block">
                      <Dropdown options={pointOfSales}
                                optionLabel="name"
                                optionValue="id"
                                id={"point_of_sale"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    point_of_sale: e.value
                                  })
                                }}
                                value={formData.point_of_sale}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="payment_method" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"payment_method"}/>
                      <label htmlFor="payment_method"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('payment_method')}</label>
                      <span className="p-d-block">
                      <Dropdown options={[
                        {
                          name: 'PAYMENT_METHOD_CARD',
                          value: 'PAYMENT_METHOD_CARD'
                        },
                        {
                          name: 'PAYMENT_METHOD_CASH',
                          value: 'PAYMENT_METHOD_CASH'
                        },
                      ]}
                                optionLabel="name"
                                optionValue="value"
                                id={"payment_method"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    payment_method: e.value
                                  })
                                }}
                                value={formData.payment_method}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12"}>
                  <label>{t('order_items')}</label>
                  <div className={"p-mt-2"}>
                    {formData.order_items?.map((item, index) => {
                      return (
                        <div className={"p-grid"}>
                          <div className={"p-col-8 p-col-align-center p-text-bold"}>
                            {item.dish?.name}{item.menu_combo?.name}
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-bold p-text-right"}>
                            <InputNumber className={"w-100"} value={item.quantity} onValueChange={(e) => {
                              item.quantity = e.value
                            }}/>
                          </div>
                          <div className={"p-col-2 p-col-align-center p-text-right"}>
                            <Button type="button" icon={"pi pi-check"} loading={loading}
                                    onClick={() => {
                                      setLoading(true)
                                      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-item/" + item.id,
                                        {
                                          quantity: item.quantity,
                                        },
                                        {
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                            "Sys-name": Cookies.get("instanceId"),
                                            "Preferred-locale": Cookies.get("language")
                                          }
                                        })
                                        .then(response => {
                                          axios({
                                            method: 'get',
                                            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + window.App.state.propsOrder.id,
                                            params: {},
                                            headers: {
                                              'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                              "Sys-name": Cookies.get("instanceId"),
                                              "Preferred-locale": Cookies.get("language")
                                            }
                                          })
                                            .then(response => {
                                              setLoading(false)
                                              setFormData(response.data.data);
                                            })
                                            .catch(error => {
                                              window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                                              setLoading(false)
                                            })
                                          window.App.toastShow('success', t('success'), response.data.message)
                                        })
                                        .catch(error => {
                                          setLoading(false)
                                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                        })
                                    }}
                                    className="p-button-success"/>
                            <Button type="button" icon={"pi pi-trash"} loading={loading}
                                    onClick={() => {
                                      setLoading(true)
                                      axios.delete(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-item/" + item.id,
                                        {
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                            "Sys-name": Cookies.get("instanceId"),
                                            "Preferred-locale": Cookies.get("language")
                                          }
                                        })
                                        .then(response => {
                                          axios({
                                            method: 'get',
                                            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + window.App.state.propsOrder.id,
                                            params: {},
                                            headers: {
                                              'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                              "Sys-name": Cookies.get("instanceId"),
                                              "Preferred-locale": Cookies.get("language")
                                            }
                                          })
                                            .then(response => {
                                              setLoading(false)
                                              setFormData(response.data.data);
                                            })
                                            .catch(error => {
                                              window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                                              setLoading(false)
                                            })
                                          window.App.toastShow('success', t('success'), response.data.message)
                                        })
                                        .catch(error => {
                                          setLoading(false)
                                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                        })
                                    }}
                                    className="p-button-danger p-ml-2"/>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  {formData.id ? (
                    <div className={"p-mt-2"}>
                      <div className={"p-grid"}>
                        <div className={"p-col-4 p-col-align-center p-text-bold"}>
                          <Dropdown options={dishes}
                                    optionLabel="name"
                                    optionValue="id"
                                    id={"dishes"}
                                    emptyMessage={t('empty_message')}
                                    onChange={(e) => {
                                      setNewDishId(e.value)
                                    }}
                                    value={newDishId}
                                    className={"w-100"}
                          />
                        </div>
                        <div className={"p-col-4 p-col-align-center p-text-bold"}>
                          <Dropdown options={menucombos}
                                    optionLabel="name"
                                    optionValue="id"
                                    id={"menucombos"}
                                    emptyMessage={t('empty_message')}
                                    onChange={(e) => {
                                      setNewMenucomboId(e.value)
                                    }}
                                    value={newMenucomboId}
                                    className={"w-100"}
                          />
                        </div>
                        <div className={"p-col-2 p-col-align-center"}>
                          <InputNumber value={newDishQuantity} className={"w-100"} onValueChange={(e) => {
                            setNewDishQuantity(e.value)
                          }}/>
                        </div>
                        <div className={"p-col-2 p-col-align-center p-text-right" }>
                          <Button type="button" icon={"pi pi-plus"} loading={loading}
                                  onClick={() => {
                                    if (newDishQuantity && (newDishId || newMenucomboId)) {
                                      setLoading(true)
                                      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-item",
                                        {
                                          quantity: newDishQuantity,
                                          dish: newDishId,
                                          menu_combo: newMenucomboId,
                                          mOrder: formData.id
                                        },
                                        {
                                          params: {},
                                          headers: {
                                            'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                            "Sys-name": Cookies.get("instanceId"),
                                            "Preferred-locale": Cookies.get("language")
                                          }
                                        })
                                        .then(response => {
                                          axios({
                                            method: 'get',
                                            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + formData.id,
                                            params: {},
                                            headers: {
                                              'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                              "Sys-name": Cookies.get("instanceId"),
                                              "Preferred-locale": Cookies.get("language")
                                            }
                                          })
                                            .then(response => {
                                              setLoading(false)
                                              setFormData(response.data.data);
                                            })
                                            .catch(error => {
                                              window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                                              setLoading(false)
                                            })
                                          window.App.toastShow('success', t('success'), response.data.message)
                                        })
                                        .catch(error => {
                                          setLoading(false)
                                          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
                                        })
                                      setNewDishId(null)
                                      setNewMenucomboId(null)
                                      setNewDishName('')
                                      setNewDishQuantity(null)
                                    }
                                  }}
                                  className="p-button-success"/>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <>
                      {t('save_before_add_items')}
                    </>
                  )}
                </div>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12 p-text-right p-text-bold"}>
                  {t('total')}: {formData.total}
                </div>
              </div>
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_NR_OrderFormComponent);
