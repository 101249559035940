import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import axios from "axios"; import Cookies from "js-cookie";
import {OverlayPanel} from "primereact/overlaypanel";

import HelpComponent from "../HelpComponent";
import _FM_BerthFormComponent from "../_FM_berth/_FM_BerthFormComponent";

const _FM_AccommodationShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opb = useRef(null);

  useEffect(() => {
    if (window.App.state.propsAccommodation && window.App.state.propsAccommodation.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation/" + window.App.state.propsAccommodation.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          window.App.setState({rerenderBerthList: false})
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.rerenderBerthList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('accommodation_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarAccommodationShow: false,
                        sidebarAccommodationForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="short_name">{t('short_name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.short_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="contact_name">{t('contact_name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.contact_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="contact_email">{t('contact_email')}</label>
              <span className="p-d-block p-text-bold">
                {formData.contact_email}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="contact_phone">{t('contact_phone')}</label>
              <span className="p-d-block p-text-bold">
                {formData.contact_phone}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="address">{t('address')}</label>
              <span className="p-d-block p-text-bold">
                {formData.country} {formData.zip_code} {formData.city}, {formData.street_and_number}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="operator_data">{t('operator_data')}</label>
              <span className="p-d-block p-text-bold">
                {formData.operator_data?.company_name}
              </span>
            </div>
          </div>
          {formData && formData.country && formData.country === 'HU' &&
          <>
            <div className={"p-col-12"}>
              <hr/>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <div className="p-field p-fluid">
                <label htmlFor="ntak_regisztracio_szam">{t('ntak_regisztracio_szam')}</label>
                <span className="p-d-block p-text-bold">
                {formData.accommodation_detail_n_t_a_k[0]?.ntak_regisztracio_szam}
              </span>
              </div>
            </div>
            <div className={"p-col-12 p-lg-6"}>
              <div className="p-field p-fluid">
                <label htmlFor="ntak_adatszolgaltatasi_azon">{t('ntak_adatszolgaltatasi_azon')}</label>
                <span className="p-d-block p-text-bold">
                {formData.accommodation_detail_n_t_a_k[0]?.ntak_adatszolgaltatasi_azon}
              </span>
              </div>
            </div>
          </>
          }



        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_FM_AccommodationShowComponent);
