import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import RegistrationsListComponent from "../components/registrations/RegistrationsListComponent";
import RegistrationsShowComponent from "../components/registrations/RegistrationsShowComponent";

const RegistrationsPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('registrations') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="RegistrationsPage p-component p-m-lg-4">
      <div className={"p-p-3 p-p-lg-0"}>
        <Panel>
          <RegistrationsListComponent />
        </Panel>
        <Sidebar position="right" visible={window.App.state.sidebarRegistrationsShow} closeOnEscape={true}
                 style={{width: '780px'}}
                 onHide={() => window.App.setState({
                   sidebarRegistrationsShow: false,
                 })}>
          <RegistrationsShowComponent />
        </Sidebar>
      </div>
    </div>
  )
}
export default withTranslation('common')(RegistrationsPage);
