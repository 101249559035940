import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Card} from "primereact/card";
import {Chart} from 'primereact/chart';
import {Dialog} from "primereact/dialog";
import {TabView, TabPanel} from 'primereact/tabview';
import {Button} from "primereact/button";
import axios from "axios"; import Cookies from "js-cookie";

import ThemeselectComponent from "../components/ThemeselectComponent";
import LangselectComponent from "../components/LangselectComponent";
import OperatorFormComponent from "../components/operator/OperatorFormComponent";
import _PC_RevolutsettingsFormComponent from "../components/_PC_revolutsettings/_PC_RevolutsettingsFormComponent";
import _PC_AcceptingplaceFormComponent from "../components/_PC_acceptingplace/_PC_AcceptingplaceFormComponent";

const _PC_ControlpanelPage = (props) => {

  const {t, i18n} = props;
  const [wizard, setWizard] = useState(false);
  const [wizardOperator, setWizardOperator] = useState(false);
  const [wizardRevolutSettings, setWizardRevolutSettings] = useState(false);
  const [wizardAcceptingPlaces, setWizardAcceptingPlaces] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    document.title = t('control_panel') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        if (!response.data.data.items[0]) {
          setWizardOperator(true)
          setWizard(true)
        } else {
          window.App.setState({selectedOperatorId: response.data.data.items[0].id})
          setWizardOperator(false)
          setActiveIndex(1)
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "revolut-settings",
            params: {},
            headers: {
              'Authorization': 'Bearer ' + Cookies.get("userToken"),
              "Sys-name": Cookies.get("instanceId"),
              "Preferred-locale": Cookies.get("language")
            }
          })
            .then(response => {
              if (!response.data.data.items[0]) {
                setWizardRevolutSettings(true)
                setWizard(true)
              } else {
                window.App.setState({selectedRevolutsettingsId: response.data.data.items[0].id})
                setWizardRevolutSettings(false)
                setActiveIndex(2)
                axios({
                  method: 'get',
                  url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accepting-place",
                  params: {},
                  headers: {
                    'Authorization': 'Bearer ' + Cookies.get("userToken"),
                    "Sys-name": Cookies.get("instanceId"),
                    "Preferred-locale": Cookies.get("language")
                  }
                })
                  .then(response => {
                    if (!response.data.data.items[0]) {
                      setWizardAcceptingPlaces(true)
                      setWizard(true)
                    } else {
                      setWizardAcceptingPlaces(false)
                      setWizard(false)
                    }
                  })
                  .catch(error => {

                  })
              }
            })
            .catch(error => {

            })
        }
      })
      .catch(error => {

      })
    window.App.setState({
      rerenderOperatorList: false,
      rerenderRevolutsettingsList: false,
      rerenderAcceptingplaceList: false
    })
  }, [window.App.state.rerenderOperatorList === true, window.App.state.rerenderRevolutsettingsList === true, window.App.state.rerenderAcceptingplaceList === true]);

  return (
    <div className="_PC_ControlpanelPage p-component p-m-lg-4">
      {(!localStorage.getItem('adminSession') || localStorage.getItem('adminSession') === 'false') ? (
        <>
          {wizard === true &&
          <Button type="button" className="p-button-warning" icon={"pi pi-cog"} id={"debugButton"} label={t('wizard')}
                  onClick={() => {
                    window.App.setState({rerenderOperatorList: true})
                  }}/>
          }
          <Dialog header={t('wizard')} visible={wizardOperator || wizardAcceptingPlaces || wizardRevolutSettings}
                  onHide={() => {
                    setWizardOperator(false)
                    setWizardAcceptingPlaces(false)
                    setWizardRevolutSettings(false)
                  }} style={{minWidth: '780px', width: '780px', maxWidth: '780px'}} draggable={false}>
            <TabView scrollable={false} id="tabScroll" activeIndex={activeIndex}
                     onTabChange={(e) => {
                     }}>
              <TabPanel header={t('operator_settings')}>
                <div className={""}>
                  <OperatorFormComponent/>
                </div>
              </TabPanel>
              <TabPanel header={t('revolut_settings')}>
                <div className={""}>
                  <_PC_RevolutsettingsFormComponent/>
                </div>
              </TabPanel>
              <TabPanel header={t('acceptingplace_settings')}>
                <div className={""}>
                  <_PC_AcceptingplaceFormComponent/>
                </div>
              </TabPanel>
            </TabView>
            <hr/>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <LangselectComponent/>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center p-text-center"}>
                <ThemeselectComponent/>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center p-text-right"}>
                <Button icon="pi pi-times" label={t('close')} className="p-button-secondary"
                        onClick={() => {
                          setWizardOperator(false)
                          setWizardAcceptingPlaces(false)
                          setWizardRevolutSettings(false)
                        }}/>
              </div>
            </div>
          </Dialog>
          <div className={"p-p-3 p-p-lg-0"}>
            <div className={"p-grid kpi"} style={{maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Vásárlások</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        40
                      </span>
                      &nbsp;
                      tranzakció
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Visszatérítések</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        4
                      </span>
                      &nbsp;
                      tranzakció
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Bevételek</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        2 111 420 HUF
                      </span>
                      &nbsp;
                      teljes bevétel
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Átlagos kosárérték</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        32 000 HUF
                      </span>
                      &nbsp;
                      átlagos kosárérték
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Elfogadóhelyek</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        23
                      </span>
                      &nbsp;
                      Üzlet 1
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        20
                      </span>
                      &nbsp;
                      Webshop 2
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        80
                      </span>
                      &nbsp;
                      Webshop 1
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className={"p-grid p-mt-0 chart"}
                 style={{maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Csatonák</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Direkt', 'Facebook', 'Google'],
                      datasets: [
                        {
                          data: [540, 325, 702],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Szolgáltatók aránya</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Revolut', 'Stripe', 'OTP SimplePay'],
                      datasets: [
                        {
                          data: [222140, 23540, 1325],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Feliratkozás típusok</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Csomag 1', 'Csomag 2', 'Csomag 3', 'Csomag 4', 'Csomag 5'],
                      datasets: [
                        {
                          data: [4, 12, 1, 4, 5],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
            </div>
          </div>
        </>
      ) : (
        <>
        </>
      )}
    </div>
  )
}
export default withTranslation('common')(_PC_ControlpanelPage);
