import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Sidebar} from "primereact/sidebar";
import {addLocale} from "primereact/api";
import {Button} from "primereact/button";
import {Calendar} from "primereact/calendar";
import {Dropdown} from "primereact/dropdown";
import {Eventcalendar} from '@mobiscroll/react';
import {Tooltip} from 'primereact/tooltip';
import {InputText} from "primereact/inputtext";
import moment from "moment";
import axios from "axios"; import Cookies from "js-cookie";
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import '../assets/css/felhomatrac-calendar.css';

import _FM_ReservationFormComponent from "../components/_FM_reservation/_FM_ReservationFormComponent";
import _FM_ReservationFormResourceComponent from "../components/_FM_reservation/_FM_ReservationFormResourceComponent";
import _FM_OooberthFormComponent from "../components/_FM_oooberth/_FM_OooberthFormComponent";
import _FM_OoounitFormComponent from "../components/_FM_ooounit/_FM_OoounitFormComponent";

const _FM_CalendarPage = (props) => {

  const {t, i18n} = props;
  const [accommodations, setAccommodations] = useState([])
  const [selectedDayBefore, setSelectedDayBefore] = useState(6)
  const [selectedDate, setSelectedDate] = useState(new Date(Date.now() - 24 * 3600 * (selectedDayBefore * 1000)));
  const [categories, setCategories] = useState([])
  const [units, setUnits] = useState([])
  const [beds, setBeds] = useState([])
  const [resources, setResources] = useState([])
  const [events, setEvents] = useState([])

  const apiCall = (method, url, data) => {
    return axios({
      method: method,
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + url,
      data,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
  }

  addLocale('default', {
    firstDayOfWeek: 1,
    dayNames: [t('sunday'), t('monday'), t('tuesday'), t('wednesday'), t('thursday'), t('friday'), t('saturday')],
    dayNamesShort: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    dayNamesMin: [t('sun'), t('mon'), t('tue'), t('wed'), t('thu'), t('fri'), t('sat')],
    monthNames: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    monthNamesShort: [t('jan'), t('feb'), t('mar'), t('apr'), t('may'), t('jun'), t('jul'), t('aug'), t('sep'),
      t('oct'), t('nov'), t('dec')],
    today: t('today'),
    clear: t('delete')
  });

  useEffect(() => {
    document.title = t('calendar') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  useEffect(() => {
    apiCall('get', 'accommodation')
      .then(response => {
        setAccommodations(response.data.data.items);
        if (response.data.data && response.data.data.items && response.data.data.items[0]) {
          window.App.setState({
            selectedAccommodation: response.data.data.items[0].id
          })
        }
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }, []);

  useEffect(() => {
    if (window.App.state.selectedAccommodation) {
      apiCall('get', 'residential-category?search[accommodation]=' + window.App.state.selectedAccommodation)
        .then(response => {
          let _cats = []
          response.data.data.items.map((cat, index) => {
            _cats.push({
              id: "C-" + cat.id,
              name: cat.name,
              type: 'category',
              eventCreation: false,
              originalId: cat.id,
              level: 1,
            })
          })
          setCategories(_cats)
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
      apiCall('get', 'residential-unit/tree?search[accommodation]=' + window.App.state.selectedAccommodation)
        .then(response => {
          let _units = []
          response.data.map((unit, index) => {
            _units.push({
              id: "U-" + unit.id,
              name: unit.name,
              type: 'unit',
              eventCreation: false,
              parentId: unit.residential_category.id,
              originalId: unit.id,
              level: 2,
              children: unit.children,
              berths: unit.berths.length
            })
          })
          setUnits(_units)
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
      apiCall('get', 'berth?search[accommodation]=' + window.App.state.selectedAccommodation)
        .then(response => {
          let _beds = []
          response.data.data.items.map((bed, index) => {
            _beds.push({
              id: "B-" + bed.id,
              name: bed.name,
              type: 'bed',
              eventCreation: false,
              parentId: bed.residential_unit.id,
              originalId: bed.id,
              level: 3,
            })
          })
          setBeds(_beds)
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
    }
  }, [window.App.state.selectedAccommodation]);

  useEffect(() => {
    let _nodes = []
    categories.map((category, index) => {
      let _units = units.filter(item => item.parentId === category.originalId)
      let __units = []
      _units.map((unit, index) => {
        let _beds = beds.filter(item => item.parentId === unit.originalId)
        let _children = []
        if (unit.children && unit.children[0]) {
          unit.children.map((children, index) => {
            let __beds = []
            if (children.berths && children.berths[0]) {
              children.berths.map((bed, index) => {
                __beds.push({
                  id: "B-" + bed.id,
                  name: bed.name,
                  type: 'bed',
                  eventCreation: false,
                  parentId: children.originalId,
                  originalId: bed.id,
                  level: 4,
                })
              })
            }
            _children.push({
              id: children.id,
              name: children.name,
              type: "U-" + 'unit',
              eventCreation: false,
              parentId: unit.originalId,
              originalId: children.id,
              level: 3,
              children: __beds,
            })
          })
          _beds = [
            ..._beds,
            ..._children
          ]
        }
        __units.push({
          ...unit,
          children: _beds
        })
      })
      _nodes.push({
        ...category,
        children: __units
      })
    })
    setResources(_nodes)
  }, [categories, units, beds]);

  useEffect(() => {
    apiCall('get', 'reservation')
      .then(response => {
        window.App.setState({rerenderReservationList: false})
        let _events = []
        response.data.data.items.map((reservation, index) => {
          let resource
          let hasBeds = ''
          let relocated = ''
          if (reservation.residential_unit && reservation.berth === null) {
            resource = "U-" + reservation.residential_unit.id
            let unit = units.filter(item => item.originalId === reservation.residential_unit.id)
            if (unit && unit[0] && unit[0].berths) {
              hasBeds = 'hasBeds hasBeds-' + unit[0].berths
            }
            if (reservation.relocated_to) {
              relocated = '/B'
            }
            if (reservation.relocated_from) {
              relocated = '/C'
            }
          } else {
            resource = "B-" + reservation.berth.id
          }
          _events.push({
            id: reservation.id,
            start: reservation.reserved_from,
            end: reservation.reserved_to,
            title: reservation.id,
            contact: '',
            guests: '',
            status: 'confirmed ' + hasBeds,
            note: reservation.note,
            reservationId: "" + reservation.id + relocated,
            billingStatus: 'unpaid',
            resource: resource,
          })
        })
        setEvents(_events);
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }, [resources, window.App.state.rerenderReservationList === true])

  // useEffect(() => {
  //   console.log(selectedDate)
  // }, [selectedDate])

  return (
    <div className="_FM_CalendarPage p-component p-my-lg-4 p-mx-lg-1">
      <div className={""}>
        <div className={"p-grid p-mb-2"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            <Button label={"-14"}
                    className={"p-mr-2 p-button-warning"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore + 14
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Button label={"-7"}
                    className={"p-mr-2 p-button-warning"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore + 7
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Button label={"-2"}
                    className={"p-mr-2 p-button-warning"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore + 2
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Button label={t('today')}
                    className={"p-mr-2 p-button-info"}
                    onClick={() => {
                      setSelectedDayBefore(-0)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (-0 * 1000)))
                    }}/>
            <Button label={"+2"}
                    className={"p-mr-2 p-button-success"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore - 2
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Button label={"+7"}
                    className={"p-mr-2 p-button-success"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore - 7
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Button label={"+14"}
                    className={"p-mr-2 p-button-success"}
                    onClick={() => {
                      let _dayBefore = selectedDayBefore - 14
                      setSelectedDayBefore(_dayBefore)
                      setSelectedDate(new Date(Date.now() - 24 * 3600 * (_dayBefore * 1000)))
                    }}/>
            <Calendar value={selectedDate}
                      dateFormat={t('YY-MM-DD')}
                      locale={"default"}
                      showIcon
                      onChange={(e) => {
                        setSelectedDayBefore(0)
                        setSelectedDate(e.value)
                      }}/>
          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>
            <Button aria-label={t('sum')}
                    className={"p-ml-2 p-button-success "}
                    onClick={() => {

                    }}>
              <span>&#8721;</span>
            </Button>
            <Dropdown options={accommodations}
                      className={"p-ml-2"}
                      optionLabel="name"
                      optionValue="id"
                      filter
                      id={"accommodation"}
                      emptyMessage={t('empty_message')}
                      onChange={(e) => {
                        window.App.setState({selectedAccommodation: e.value})
                      }}
                      value={window.App.state.selectedAccommodation}
            />
            <Button label={t('bills')}
                    icon={"pi pi-list"}
                    className={"p-ml-2 p-button-warning"}
                    onClick={() => {

                    }}/>
            <Button label={t('groups')}
                    icon={"pi pi-users"}
                    className={"p-ml-2 p-button-success"}
                    onClick={() => {

                    }}/>
            <Button label={t('reservation')}
                    icon={"pi pi-plus"}
                    className={"p-ml-2 p-button-success"}
                    onClick={() => {
                      window.App.setState({
                        propsReservation: null,
                        sidebarReservationForm: true,
                      })
                    }}/>
          </div>
        </div>
        {resources && resources[0] && categories && categories[0] && units && units[0] && events && events[0] &&
        <Eventcalendar
          dataTimezone="utc"
          displayTimezone="Europe/Budapest"
          dragToCreate={false}
          dragToMove={false}
          dragToResize={false}
          eventDelete={true}
          showControls={false}
          clickToCreate={false}
          onEventDoubleClick={(event) => {
            if (event && event.resource && !event.resource.toString().includes('C-')) {
              window.App.setState({
                propsReservation: {
                  id: event.event.id,
                  resource: event.resource,
                  reserved_from: event.event.start,
                  reserved_to: event.event.end,
                  status: event.event.status,
                  note: event.event.note,
                },
                sidebarReservationForm: true,
              })
            }

          }}
          onCellClick={(date) => {
          }}
          onCellDoubleClick={(date) => {
            if (date && date.resource && !date.resource.toString().includes('C-')) {
              window.App.setState({
                propsReservation: {
                  resource: date.resource,
                  date: date.date
                },
                sidebarReservationFormResource: true,
              })
            }
          }}
          renderMonth={() => {
          }}
          themeVariant={Cookies.get('theme')}
          renderDay={(day) => {
            return (
              <div
                className={"day p-text-center " + moment.utc(day.date).local().format('ddd').toLowerCase() + " is-active-" + day.isActive}
                data-today={moment.utc(day.date).local().format('YYYY-MM-DD') == moment.utc(new Date(Date.now())).local().format('YYYY-MM-DD')}
                data-selected={moment.utc(day.date).local().format('YYYY-MM-DD') == moment.utc(selectedDate).local().format('YYYY-MM-DD')}>
              <span className={"p-d-block"}>
                {moment.utc(day.date).local().format('DD')}
              </span>
                <span className={"p-d-block"}>
                {t(moment.utc(day.date).local().format('ddd').toLowerCase())}
              </span>
                <span className={"p-d-block"}>
                {moment.utc(day.date).local().format('YY')}
                  &nbsp;
                  {t(moment.utc(day.date).local().format('MMM').toLowerCase())}
              </span>
              </div>
            )
          }}
          renderResourceHeader={() => {
            return (
              <div className={"header"}>
                <label htmlFor="search"
                       className={""}>{t('search')}</label>
                <span className="p-d-block">
                      <InputText id="search"
                                 onChange={(e) => {

                                 }}
                                 className={"w-100 p-inputtext-sm"}
                      />
                    </span>
              </div>
            )
          }}
          renderResource={(resource) => {
            return (
              <div className={"resource-item " + resource.type + " resource-level-" + resource.level}>
              <span className={""}>
                {resource.name}
                &nbsp;
                {(resource.type === 'bed' || resource.type === 'unit') &&
                  <>
                    <i className={"pi pi-ban tooltip-" + resource.id}
                       style={{float: "right", marginTop: "3px"}}
                       onClick={() => {
                         if(resource.type === 'bed') {
                           window.App.setState({
                             sidebarOooberthForm: true,
                             selectedBerthId: resource.originalId
                           })
                         }
                         if(resource.type === 'unit') {
                           window.App.setState({
                             sidebarOoounitForm: true,
                             selectedUnitId: resource.originalId
                           })
                         }
                       }}>
                    </i>
                    <Tooltip target={".tooltip-" + resource.id}>
                      {t('set_ooo')}
                    </Tooltip>
                  </>
                }
              </span>
              </div>
            )
          }}
          renderScheduleEvent={(event) => {
            if (event && event.original && event.original.status && event.original.status == 'occupancy') {
              return (
                <div className={"event " + event.original.status + " " + event.currentResource.type}>
                  {event.title}
                </div>
              )
            } else {
              let moved = ''
              if (event.original.reservationId.includes('/C') || event.original.reservationId.includes('/B')) {
                moved = 'moved'
              }
              return (
                <div
                  className={"event " + event.original.status + " " + event.currentResource.type + " " + "tooltip-" + event.original.reservationId.replace('/', '')}>
                  <span className={"p-badge " + event.original.billingStatus + " " + moved}>
                    {event.original.reservationId.includes('/C') &&
                    <i className="pi pi-sign-out p-mr-2"></i>
                    }
                    {event.original.reservationId.replace('/B', '').replace('/C', '')}
                    {event.original.reservationId.includes('/B') &&
                    <i className="pi pi-sign-in p-ml-2"></i>
                    }
                  </span>
                  &nbsp;{event.title}
                  <Tooltip target={".tooltip-" + event.original.reservationId.replace('/', '')}>
                    {event.original.contact &&
                    <span className={"p-d-block"}>
                      <i className={"pi pi-info-circle p-mr-2"}></i>{event.original.contact}
                    </span>
                    }
                    {event.original.guests &&
                    <span className={"p-d-block"}>
                      <i className={"pi pi-users p-mr-2"}></i>{event.original.guests}
                    </span>
                    }
                  </Tooltip>
                </div>
              )
            }
          }}
          defaultSelectedDate={selectedDate}
          selectedDate={selectedDate}
          refDate={selectedDate}
          responsive={{
            custom: { // Custom breakpoint
              breakpoint: 1480,
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 16,
                }
              }
            },
            custom2: { // Custom breakpoint
              breakpoint: 1680,
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 19,
                }
              }
            },
            custom3: { // Custom breakpoint
              breakpoint: 1920,
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 22,
                }
              }
            },
            xsmall: {
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 2,
                }
              }
            },
            small: {
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 4,
                }
              }
            },
            medium: {
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 8,
                }
              }
            },
            large: {
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 10,
                }
              }
            },
            xlarge: {
              view: {
                timeline: {
                  type: 'day',
                  resolutionHorizontal: 'day',
                  size: 12,
                }
              }
            }
          }}
          data={events}
          resources={resources}
        />
        }
      </div>
      <Sidebar position="right" visible={window.App.state.sidebarReservationForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarReservationForm: false,
               })}>
        <_FM_ReservationFormComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarReservationFormResource} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarReservationFormResource: false,
               })}>
        <_FM_ReservationFormResourceComponent/>
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOooberthForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOooberthForm: false,
               })}>
        <_FM_OooberthFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOoounitForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOoounitForm: false,
               })}>
        <_FM_OoounitFormComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_CalendarPage);
