import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";

const _NR_MenucomboShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsMenucombo && window.App.state.propsMenucombo.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu-combo/" + window.App.state.propsMenucombo.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('menucombo_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarMenucomboShow: false,
                        sidebarMenucomboForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="category">{t('category')}</label>
              <span className="p-d-block p-text-bold">
                {formData.main_category?.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <hr/>
          </div>
          <div className={"p-col-12"}>
            <label>{t('dishes')}</label>
            <div className={"p-mt-2"}>
              {formData.dishes?.map((dish, index) => {
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-2 p-col-align-center p-text-bold"}>
                      {dish.id}
                    </div>
                    <div className={"p-col-10 p-col-align-center p-text-bold"}>
                      {dish.name}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={"p-col-12"}>
            <hr/>
          </div>
          <div className={"p-col-12"}>
            <label>{t('prices')}</label>
            <div className={"p-mt-2"}>
              {formData.prices?.map((price, index) => {
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-4 p-col-align-center p-text-bold"}>
                      {price.type}
                    </div>
                    <div className={"p-col-6 p-col-align-center p-text-bold p-text-right"}>
                      {price.amount}
                    </div>
                    <div className={"p-col-2 p-col-align-center p-text-bold"}>
                      {price.currency}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
        </div>

      </Panel>
    </>
  )
}
export default withTranslation('common')(_NR_MenucomboShowComponent);
