import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_OrderListComponent from "../components/_NR_order/_NR_OrderListComponent";
import _NR_OrderFormComponent from "../components/_NR_order/_NR_OrderFormComponent";
import _NR_OrderShowComponent from "../components/_NR_order/_NR_OrderShowComponent";

const _NR_OrderPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('order') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_OrderPage p-component p-m-lg-4">
      <Panel>
        <_NR_OrderListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOrderForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOrderForm: false,
               })}>
        <_NR_OrderFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOrderShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOrderShow: false,
               })}>
        <_NR_OrderShowComponent />
      </Sidebar>

    </div>
  )
}
export default withTranslation('common')(_NR_OrderPage);
