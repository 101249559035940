import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Dropdown} from "primereact/dropdown";
import Cookies from "js-cookie";

const LangselectComponent = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
  }, [])

  return (
    <Dropdown options={[
      {name: 'English', code: 'en'},
      {name: 'Magyar', code: 'hu'},
    ]}
              optionLabel="name"
              optionValue="code"
              onChange={(e) => {
                window.App.changeLanguage(e.value)
                window.App.setState({rerenderNavbar: true})
                window.App.forceUpdate()
              }}
              value={Cookies.get('language')}
    />
  )
}
export default withTranslation('common')(LangselectComponent);
