import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_OrderspotListComponent from "../components/_NR_orderspot/_NR_OrderspotListComponent";
import _NR_OrderspotFormComponent from "../components/_NR_orderspot/_NR_OrderspotFormComponent";
import _NR_OrderspotShowComponent from "../components/_NR_orderspot/_NR_OrderspotShowComponent";

const _NR_OrderspotPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('orderspot') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_OrderspotPage p-component p-m-lg-4">
      <Panel>
        <_NR_OrderspotListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOrderspotForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOrderspotForm: false,
               })}>
        <_NR_OrderspotFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOrderspotShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOrderspotShow: false,
               })}>
        <_NR_OrderspotShowComponent />
      </Sidebar>

    </div>
  )
}
export default withTranslation('common')(_NR_OrderspotPage);
