import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_AccommodationListComponent from "../components/_FM_accommodation/_FM_AccommodationListComponent";
import _FM_AccommodationFormComponent from "../components/_FM_accommodation/_FM_AccommodationFormComponent";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import _FM_AccommodationShowComponent from "../components/_FM_accommodation/_FM_AccommodationShowComponent";

const _FM_AccomodatonPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('accommodation') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_AccomodatonPage p-component p-m-lg-4">
      <Panel>
        <_FM_AccommodationListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarAccommodationForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarAccommodationForm: false,
               })}>
        <_FM_AccommodationFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarAccommodationShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarAccommodationShow: false,
               })}>
        <_FM_AccommodationShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_AccomodatonPage);
