import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import {OverlayPanel} from "primereact/overlaypanel";
import {InputText} from "primereact/inputtext";
import axios from "axios"; import Cookies from "js-cookie";
import moment from "moment";

import _FM_BerthFormComponent from "../_FM_berth/_FM_BerthFormComponent";
import _FM_UnitFormComponent from "../_FM_unit/_FM_UnitFormComponent";
import HelpComponent from "../HelpComponent";
import {ConfirmDialog} from "primereact/confirmdialog";

const _FM_ReservationListComponent = (props) => {
  const {t} = props;
  const [reservation, setReservation] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const dt = useRef(null);
  const opu = useRef(null);
  const opb = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let query = window.App.transformQueryParams(lazyParams)
      window.App.setState({rerenderReservationList: false, rerenderUnitList: false, rerenderBerthList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "reservation" + query,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setTotalRecords(response.data.data.total_item_count);
          setReservation(response.data.data.items);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', reservationSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', reservationSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-pencil" className="p-button-sm  p-button-warning p-mr-2"
                tooltip="Módosítás"
                tooltipOptions={{position: 'left'}}
                onClick={() => {
                  window.App.setState({
                    sidebarReservationForm: true,
                    propsReservation: {id: rowData.id}
                  })
                }}/>
        {rowData && rowData.deleted_at_snapshot ? (
          <Button icon="pi pi-undo" className="p-button-sm p-button-success"
                  tooltip="Visszaállítás"
                  tooltipOptions={{position: 'left'}}
                  onClick={() => {
                    axios({
                      method: 'get',
                      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "reservation/" + rowData.id + "/restore",
                      params: {},
                      headers: {
                        'Authorization': 'Bearer ' + Cookies.get("userToken"),
                        "Sys-name": Cookies.get("instanceId"),
                        "Preferred-locale": Cookies.get("language")
                      }
                    })
                      .then(response => {
                        window.App.toastShow('success', t('success_restore'),);
                        loadLazyData();
                      })
                      .catch(error => {
                        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                      })
                  }}
          />
        ) : (
          <>
            <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                    tooltip={t('delete')}
                    tooltipOptions={{position: 'left'}}
                    onClick={() => {
                      window.App.setState({[`confirmDelete${rowData.id}`]: true})
                    }}
            />
            <ConfirmDialog group="declarative" visible={window.App.state[`confirmDelete${rowData.id}`]}
                           acceptLabel={t('yes')} rejectLabel={t('no')}
                           onHide={() => {
                             window.App.setState({[`confirmDelete${rowData.id}`]: false})
                           }}
                           message={t('confirm_delete')} header={t('confirm_delete')} icon="pi pi-exclamation-triangle"
                           accept={() => {
                             window.App.delete('reservation', rowData.id)
                               .then(response => {
                                 window.App.toastShow('success', t('success_delete'),);
                                 loadLazyData();
                               })
                               .catch(error => {
                                 window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                               })
                           }}
                           reject={() => {
                             window.App.setState({[`confirmDelete${rowData.id}`]: false})
                           }}/>
          </>
        )}
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderReservationList === true, window.App.state.rerenderUnitList === true, window.App.state.rerenderBerthList === true])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col"}>
          <h2 className={"p-m-0"}>{t('reservation_list')}</h2>
        </div>
        <div className={"p-col p-text-right p-col-align-center"}>
          <HelpComponent type={"site"} position={"UNDER_HELP_BUTTON"}/>
          {lazyParams && lazyParams.is_active === 0 ? (
            <Button type="button" icon={"pi pi-eye-slash"}
                    onClick={() => {
                      let _lazyParams = lazyParams
                      delete lazyParams.is_active
                      setLazyParams(_lazyParams)
                      loadLazyData()
                    }}
                    className="p-button-danger p-ml-2"/>
          ) : (
            <Button type="button" icon={"pi pi-eye"}
                    onClick={() => {
                      setLazyParams({
                        ...lazyParams,
                        is_active: 0
                      })
                    }}
                    className="p-button-success p-ml-2"/>
          )}
          <span className="p-input-icon-left p-ml-2">
              <i className="pi pi-search"/>
              <InputText id="search"
                         placeholder={t('search')}
                         onChange={(e) => {
                           clearTimeout(window.searchTimeout)
                           window.searchTimeout = setTimeout(function () {
                             setLazyParams({
                               ...lazyParams,
                               search: e.target.value
                             })
                           }, 1000)
                         }}
                         className={""}
              />
            </span>
          <Button type="button" label={t('new_reservation')} icon={"pi pi-plus"}
                  onClick={() => {
                    window.App.setState({
                      propsReservation: null,
                      sidebarReservationForm: true,
                      selectedAccommodation: null
                    })
                  }}
                  className="p-button-success p-ml-2"/>
        </div>
      </div>
      <HelpComponent type={"site"} position={"TOP_OF_THE_PAGE"}/>
      <DataTable
        ref={dt}
        size={"small"}
        emptyMessage={t('empty_message')}
        value={reservation} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage={t('empty_message')}
        autoLayout={true}
        filterDisplay="menu"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
        selectionMode={"single"}
        onRowDoubleClick={(rowData) => {
          window.App.setState({
            sidebarReservationForm: true,
            propsReservation: {id: rowData.data.id}
          })
        }}
      >
        <Column field="id" sortable header={"#"}></Column>
        <Column field="reserved_from" sortable header={t('reserved_from')} body={(rowData) => {
          if (rowData.reserved_from !== null) {
            return (
              <span>{moment.utc(rowData.reserved_from).local().format(t('YYYY-MM-DD HH:mm'))}</span>
            )
          }
        }}></Column>
        <Column field="reserved_to" sortable header={t('reserved_to')} body={(rowData) => {
          if (rowData.reserved_to !== null) {
            return (
              <span>
                {moment.utc(rowData.reserved_to).local().format(t('YYYY-MM-DD HH:mm'))}
                {rowData.relocated_to &&
                <>
                  <span className={"p-badge p-badge-warning p-ml-2"}>{t('relocated_to')}<i
                    className="pi pi-sign-in p-ml-1" style={{fontSize: "0.6rem"}}></i></span>
                </>
                }
                {rowData.relocated_from &&
                <>
                  <span className={"p-badge p-badge-warning p-ml-2"}><i className="pi pi-sign-out p-mr-1"
                                                                        style={{fontSize: "0.6rem"}}></i>{t('relocated_from')}</span>
                </>
                }
              </span>
            )
          }
        }}></Column>
        <Column field="residential_unit.name" header={t('residential_unit')}
                body={(rowData) => {
                  if (rowData && rowData.residential_unit) {
                    return (
                      <>
                        {rowData.residential_unit.name}
                        &nbsp;
                        <i className={"pi pi-window-maximize p-ml-2"}
                           onClick={(e) => {
                             opu.current.toggle(e)
                             window.App.setState({
                               propsUnit: {id: rowData.residential_unit.id}
                             })
                           }}
                        ></i>
                        <OverlayPanel ref={opu} showCloseIcon>
                          <_FM_UnitFormComponent/>
                        </OverlayPanel>
                      </>
                    )
                  }
                }}
        ></Column>
        <Column field="berth.name" header={t('berth')}
                body={(rowData) => {
                  if (rowData && rowData.berth) {
                    return (
                      <>
                        {rowData.berth.name}
                        &nbsp;
                        <i className={"pi pi-window-maximize p-ml-2"}
                           onClick={(e) => {
                             opb.current.toggle(e)
                             window.App.setState({
                               propsBerth: {id: rowData.berth.id}
                             })
                           }}
                        ></i>
                        <OverlayPanel ref={opb} showCloseIcon>
                          <_FM_BerthFormComponent/>
                        </OverlayPanel>
                      </>
                    )
                  }
                }}
        ></Column>
        <Column field="note" header={t('note')} sortable></Column>
        <Column field="status" header={t('status')} sortable></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap p-text-right"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(_FM_ReservationListComponent);
