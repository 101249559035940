import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_MenucomboListComponent from "../components/_NR_menucombo/_NR_MenucomboListComponent";
import _NR_MenucomboFormComponent from "../components/_NR_menucombo/_NR_MenucomboFormComponent";
import _NR_MenucomboShowComponent from "../components/_NR_menucombo/_NR_MenucomboShowComponent";

const _NR_MenucomboPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('menucombo') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_MenucomboPage p-component p-m-lg-4">
      <Panel>
        <_NR_MenucomboListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarMenucomboForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarMenucomboForm: false,
               })}>
        <_NR_MenucomboFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarMenucomboShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarMenucomboShow: false,
               })}>
        <_NR_MenucomboShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_NR_MenucomboPage);
