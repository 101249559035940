import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

const _NR_PublicmenuPage = (props) => {

  const {t, i18n} = props;
  const [formData, setFormData] = useState({});

  useEffect(() => {
    document.title = t('menu') + ' - ' + process.env.REACT_APP_TITLE;
    axios.get(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "public/menu/" + window.App.urlVars().hash,
      {
        params: {},
        headers: {
          "Sys-name": window.App.urlVars().systemname,
          "Preferred-locale": Cookies.get("language")
        }
      })
      .then(response => {
        setFormData(response.data.data)
      })
      .catch(error => {

      })
  }, []);

  return (
    <div className="_NR_PublicmenuPage p-component p-m-lg-4">
      <h1 className={"p-text-center"}>{formData.name}</h1>
      <Panel style={{maxWidth: "400px"}} className={"p-mx-auto"}>
        {formData.categories?.map((cat, index) => {
          return(
            <div className={"p-p-5"}>
              <h2>{cat.name}</h2>
              {cat.main_dishes?.map((maindish, index) => {
                return (
                  <div className={"p-ml-5"}>
                    <h3>{maindish.name}</h3>
                    <p className={"p-text-right"}>{maindish.dish_prices[0].amount} {maindish.dish_prices[0].currency}</p>
                  </div>
                )
              })}

              {cat.menu_combos?.map((menucombo, index) => {
                return (
                  <div className={"p-ml-5"}>
                    <h3>{menucombo.name}</h3>
                    <p className={"p-text-right"}>{menucombo.prices[0].amount} {menucombo.prices[0].currency}</p>
                  </div>
                )
              })}
            </div>
          )
        })}
      </Panel>
    </div>
  )
}
export default withTranslation('common')(_NR_PublicmenuPage);
