import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Password} from 'primereact/password';
import {Link} from "react-router-dom";
import {Button} from 'primereact/button';
import {Panel} from "primereact/panel";
import {Dropdown} from "primereact/dropdown";
import axios from "axios";
import Cookies from "js-cookie";
import LangselectComponent from "../components/LangselectComponent";
import ThemeselectComponent from "../components/ThemeselectComponent";

const LoginPage = (props) => {

  const [formData, setFormData] = useState({});
  const [instances, setInstances] = useState([]);
  const [loading, setLoading] = useState(false);
  const {t, i18n} = props;

  const validate = (data) => {
    let errors = {};
    if (!data.email) {
      errors.email = t('this_input_cannot_be_empty');
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(data.email)) {
      errors.email = t('please_use_a_valid_email_address');
    }
    // if (!data.password) {
    //   errors.password = 'A jelszó nem lehet üres';
    // }
    return errors;
  };

  const onSubmit = (data, form) => {
    setLoading(true)
    setFormData(data);
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "login_check",
      {
        username: data.email,
        password: data.password
      },
      {
        params: {},
        headers: {
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
      .then(response => {
        document.getElementById('body').classList.remove('outside')
        if (localStorage.getItem('adminSession') === 'true' && Cookies.get('roles') &&
          Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN')) {
          document.getElementById('body').classList.add('admin')
        } else {
          document.getElementById('body').classList.remove('admin')
        }
        if (window.location.hostname.split('.').slice(0)[0] !== 'app') {
          Cookies.set('userToken', response.data.token, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          Cookies.set('userId', response.data.user.id, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          Cookies.set('roles', response.data.user.roles, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          window.location.replace('/#/');
          window.App.forceUpdate()
        } else {
          Cookies.set('userToken', response.data.token, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          Cookies.set('userId', response.data.user.id, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          Cookies.set('roles', response.data.user.roles, {
            expires: 7,
            secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
            sameSite: 'Strict',
            path: '/',
            domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
          });
          setTimeout(() => {
            window.location.replace(process.env.REACT_APP_APP_PROTOCOL + Cookies.get("instanceId") + '.' + process.env.REACT_APP_APP_HOST);
          }, 500)
        }
      })
      .catch(error => {
        setLoading(false)
        if (error && error.response && error.response.status && error.response.status == 404) {
          window.App.toastShow('warn', t('warning'), t('no_instance_found_for_this_email'))
        } else {
          window.App.toast.show({
            severity: 'error',
            summary: t('error'),
            detail: error.response.data.message,
            life: 4000
          });
        }
      })
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  const loginSelect = () => {
    setInstances([])
    setLoading(true)
    axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "login-select",
      formData,
      {
        params: {},
        headers: {
          "Preferred-locale": Cookies.get("language")
        }
      })
      .then(response => {
        setLoading(false)
        if (response.data.data && response.data.data.items && response.data.data.items[0]) {
          setInstances(response.data.data.items)
        } else {
          window.App.toastShow('warn', t('warning'), t('no_instance_found_for_this_email'))
        }
      })
      .catch(error => {
        setLoading(false)
        window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
      })
  }

  useEffect(() => {
    document.getElementById('body').classList.add('outside')
    document.title = t('login') + ' - ' + process.env.REACT_APP_TITLE;
    Cookies.remove('subdomain', {
      path: '/',
      domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
    });
    Cookies.remove('instanceId', {
      path: '/',
      domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
    });
    Cookies.remove('instanceName', {
      path: '/',
      domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
    });
    if (window.location.hostname.split('.').slice(0)[0] !== 'app') {
      Cookies.set('instanceId', window.location.hostname.split('.').slice(0)[0], {
        expires: 7,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
      Cookies.set('instanceName', window.location.hostname.split('.').slice(0)[0], {
        expires: 7,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
    }
    if (window.location.hostname.split('.').slice(0)[0] !== 'app') {
      Cookies.set('subdomain', window.location.hostname.split('.').slice(0)[0], {
        expires: 7,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
      window.App.forceUpdate()
    }
  }, []);

  return (
    <div className="LoginPage p-component">
      <img src={require(".." + process.env.REACT_APP_LOGO_FILE)} className="p-d-block p-my-5 p-mx-auto"/>
      <Panel>
        <div className={"p-grid p-mb-4"}>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-col-align-center"}>
            <h3 className={"p-m-0"}>
              <i className={"pi pi-user"}></i> {t('login')}
            </h3>
          </div>
          <div className={"p-col-6 p-lg-6 p-xl-6 p-text-right"}>
            <ThemeselectComponent/>
            <span className={"p-ml-2"}>
              <LangselectComponent/>
            </span>
          </div>
        </div>
        <Form onSubmit={onSubmit}
              className="p-d-block p-mx-auto"
              initialValues={formData}
              validate={validate} render={({handleSubmit, submitting}) => (
          <form onSubmit={handleSubmit}>
            <div className={"p-grid"}>
              <div className={"p-col-12"}>
                <Field name="email" tabindex="1" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="email"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('email')}</label>
                    <div className={"p-inputgroup"}>
                      <InputText id="email"
                                 {...input}
                                 autoFocus
                                 value={formData.email}
                                 onBlur={(e) => {
                                   if (window.location.hostname.split('.').slice(0)[0] === 'app' && e.target.value) {
                                     loginSelect()
                                   }
                                 }}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 onKeyUp={(e) => {
                                   if (e.target.value && e.key === 'Enter') {
                                     loginSelect()
                                   }
                                 }}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                      {!Cookies.get("subdomain") &&
                      <Button type="button" className="p-button-success" icon={"pi pi-chevron-right"}
                              loading={loading}
                              onClick={() => {
                                if (!Object.keys(validate(formData))[0]) {
                                  loginSelect()
                                }
                              }}
                      />
                      }
                    </div>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              {instances && instances[0] && !Cookies.get("subdomain") &&
              <div className={"p-col-12"}>
                <Field name="instance" tabindex="2" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="instance"
                           className={classNames({'p-error': isFormFieldValid(meta)})}>{t('instance')}</label>
                    <span className="p-d-block">
                                <Dropdown {...input}
                                          autoFocus
                                          onChange={(e) => {
                                            Cookies.set('instanceId', e.target.value, {
                                              expires: 7,
                                              secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
                                              sameSite: 'Strict',
                                              path: '/',
                                              domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
                                            });
                                            setTimeout(() => {
                                              Cookies.set('instanceName', document.getElementById('instance').getElementsByClassName("p-inputtext")[0].innerHTML, {
                                                expires: 7,
                                                secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
                                                sameSite: 'Strict',
                                                path: '/',
                                                domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
                                              });
                                            }, 1000)
                                          }}
                                          value={Cookies.get("instanceId")}
                                          options={instances}
                                          optionLabel="system_name"
                                          optionValue="system_name_slugged"
                                          id={"instance"}
                                          emptyMessage={t('empty_message')}
                                          className={classNames({'p-error': isFormFieldValid(meta)})}
                                />
                            </span>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
              {Cookies.get("instanceId") &&
              <div className={"p-col-12"}>
                <Field name="password" tabindex="3" render={({input, meta}) => (
                  <div className="p-field p-fluid">
                    <label htmlFor="password"
                           className={"" + classNames({'p-error': isFormFieldValid(meta)})}>{t('password')}</label>
                    <Password id="password"
                              {...input}
                              toggleMask
                              feedback={false}
                              value={formData.password}
                              onChange={(e) => {
                                setFormData({
                                  ...formData,
                                  password: e.target.value
                                })
                              }}
                              className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    {getFormErrorMessage(meta)}
                  </div>
                )}/>
              </div>
              }
            </div>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-col-align-center"}>
                {Cookies.get("instanceId") &&
                <>
                  <Link to={"/password-reset"} className={"p-d-block"} tabindex="-1">{t('forgotten_password')}</Link>
                </>
                }
                {!Cookies.get("instanceId") && process.env.REACT_APP_REGISTRATION === 'enabled' &&
                <Link to={"/registration"} className={"p-d-block"} tabindex="-1">{t('registration')}</Link>
                }
              </div>
              <div className={"p-col-12 p-lg-6 p-xl-6 p-text-right"} tabindex="4">
                {formData.email && Cookies.get("instanceId") && formData.password ? (
                  <Button type="submit"
                          label={t('login')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled={submitting}/>
                ) : (
                  <Button type="submit"
                          label={t('login')}
                          className="p-button-success"
                          icon={"pi pi-check"}
                          loading={loading} disabled/>
                )}
              </div>
            </div>
          </form>
        )}/>
      </Panel>
    </div>
  )
}
export default withTranslation('common')(LoginPage);
