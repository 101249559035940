import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import SubscriptionplanListComponent from "../components/subscriptionplan/SubscriptionplanListComponent";
import SubscriptionplanShowComponent from "../components/subscriptionplan/SubscriptionplanShowComponent";

const SubscriptionplanPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('subscriptionplan') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="SubscriptionplanPage p-component p-m-lg-4">
      <div className={"p-p-3 p-p-lg-0"}>
        <Panel>
          <SubscriptionplanListComponent />
        </Panel>
        <Sidebar position="right" visible={window.App.state.sidebarSubscriptionplanShow} closeOnEscape={true}
                 style={{width: '780px'}}
                 onHide={() => window.App.setState({
                   sidebarSubscriptionplanShow: false,
                 })}>
          <SubscriptionplanShowComponent />
        </Sidebar>
      </div>
    </div>
  )
}
export default withTranslation('common')(SubscriptionplanPage);
