import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";
import QRCode from "react-qr-code";

const _NR_OrderShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsOrder && window.App.state.propsOrder.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order/" + window.App.state.propsOrder.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('order_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarOrderShow: false,
                        sidebarOrderForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('orderspot')}</label>
              <span className="p-d-block p-text-bold">
                {formData.order_spot?.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="pointofsale">{t('pointofsale')}</label>
              <span className="p-d-block p-text-bold">
                {formData.point_of_sale?.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="payment_method">{t('payment_method')}</label>
              <span className="p-d-block p-text-bold">
                {formData.payment_method}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="status">{t('status')}</label>
              <span className="p-d-block p-text-bold">
                {formData.status}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <hr/>
          </div>
          <div className={"p-col-12"}>
            <label>{t('order_items')}</label>
            <div className={"p-mt-2"}>
              {formData.order_items?.map((item, index) => {
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-8 p-col-align-center p-text-bold"}>
                      {item.dish?.name}{item.menu_combo?.name}
                    </div>
                    <div className={"p-col-2 p-col-align-center p-text-bold p-text-right"}>
                      {item.quantity} {t('peace')}
                    </div>
                    <div className={"p-col-2 p-col-align-center p-text-bold p-text-right"}>
                      {item.price}
                    </div>
                  </div>
                )
              })}
            </div>
          </div>
          <div className={"p-col-12"}>
            <hr/>
          </div>
          <div className={"p-col-12 p-text-right p-text-bold"}>
            {t('total')}: {formData.total}
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_NR_OrderShowComponent);
