import React, {useState, useEffect, useRef} from 'react';
import {Button} from "primereact/button";
import {Link} from "react-router-dom";
import {Menubar} from "primereact/menubar";
import {withTranslation} from 'react-i18next';
import {SplitButton} from "primereact/splitbutton";
import {ConfirmDialog} from "primereact/confirmdialog";
import {Badge} from "primereact/badge";
import Cookies from "js-cookie";

import HelpComponent from "./HelpComponent";

const NavbarComponent = (props) => {

  const {t, i18n} = props;
  const [menu, setMenu] = useState([]);
  const [profile, setProfile] = useState([]);

  useEffect(() => {
    if (localStorage.getItem('adminSession') && localStorage.getItem('adminSession') === 'true' &&
      Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN')) {
      let _admin_items = [
        {
          label: t('help'),
          icon: 'pi pi-question-circle',
          url: '/#/help',
          className: 'master-data'
        },
        {
          label: t('monolog'),
          icon: 'pi pi-list',
          url: '/#/monolog',
          className: 'master-data'
        },
        {
          label: t('registrations'),
          icon: 'pi pi-users',
          url: '/#/registrations',
          className: 'master-data'
        },
      ]
      if (process.env.REACT_APP_BRAND !== 'paycenter') {
        _admin_items.push(
          {
            label: t('subscriptionplans'),
            icon: 'pi pi-history',
            url: '/#/subscriptionplan',
            className: 'master-data'
          },
        )
      }
      setMenu(_admin_items)
      let _profile = [
        {
          label: t('profile'),
          icon: 'pi pi-user',
          command: () => {
            window.location.replace('/#/profile');
            window.App.forceUpdate()
          }
        },
        {
          label: t('users'),
          icon: 'pi pi-users',
          command: () => {
            window.location.replace('/#/user');
            window.App.forceUpdate()
          }
        },
        {
          label: t('logout'),
          icon: 'pi pi-sign-out',
          command: () => {
            window.App.setState({confirmLogout: true})
          }
        },
      ]
      setProfile(_profile)
    } else {
      let _menu = [
        {
          label: t('control_panel'),
          icon: 'pi pi-sliders-v',
          url: '/#/control-panel',
          className: 'control-panel'
        }
      ]
      if (process.env.REACT_APP_BRAND === 'felhomatrac') {
        _menu.push(
          {
            label: t('calendar'),
            icon: 'pi pi-calendar',
            url: '/#/calendar',
            className: '_FM_calendar'
          },
          {
            label: t('reservations'),
            icon: 'pi pi-check-square',
            url: '/#/reservation',
            className: '_FM_reservation'
          },
          {
            label: t('master_data'),
            icon: 'pi pi-box',
            className: '_FM_master-data',
            items: [
              {
                label: t('services'),
                icon: 'pi pi-box',
                url: '/#/service',
                className: '_FM_master-data'
              },
              {
                label: t('partners'),
                icon: 'pi pi-user',
                url: '/#/partner',
                className: 'partner'
              },
              {
                label: t('builder'),
                icon: 'pi pi-sitemap',
                url: '/#/builder',
                className: '_FM_master-data highlighted'
              },
              {
                label: t('rescategories'),
                icon: 'pi pi-star',
                url: '/#/category',
                className: '_FM_master-data'
              },
              {
                label: t('units'),
                icon: 'pi pi-home',
                url: '/#/unit',
                className: '_FM_master-data'
              },
              {
                label: t('berths'),
                icon: 'pi pi-inbox',
                url: '/#/berth',
                className: '_FM_master-data'
              },
              {
                label: t('ooo_units'),
                icon: 'pi pi-inbox',
                url: '/#/ooounit',
                className: '_FM_master-data'
              },
              {
                label: t('ooo_berths'),
                icon: 'pi pi-inbox',
                url: '/#/oooberth',
                className: '_FM_master-data'
              },
              {
                label: t('payment_methods'),
                icon: 'pi pi-credit-card',
                url: '/#/payment-method',
                className: '_FM_master-data'
              },
              {
                label: t('reservation_chanels'),
                icon: 'pi pi-wifi',
                url: '/#/chanel',
                className: '_FM_master-data'
              },
            ]
          },
        )
      }
      if (process.env.REACT_APP_BRAND === 'netrendel') {
        _menu.push(
          {
            label: t('master_data'),
            icon: 'pi pi-box',
            className: '_NR_master-data',
            items: [
              {
                label: t('menus'),
                icon: 'pi pi-book',
                url: '/#/menu',
                className: '_NR_master-data'
              },
              {
                label: t('categories'),
                icon: 'pi pi-list',
                url: '/#/category',
                className: '_NR_master-data'
              },
              {
                label: t('dishes'),
                icon: 'pi pi-circle-fill',
                url: '/#/dish',
                className: '_NR_master-data'
              },
              {
                label: t('menucombos'),
                icon: 'pi pi-sitemap',
                url: '/#/menucombo',
                className: '_NR_master-data'
              },
              {
                label: t('orderspot'),
                icon: 'pi pi-qrcode',
                url: '/#/orderspot',
                className: '_NR_master-data'
              },
              {
                label: t('orders'),
                icon: 'pi pi-check',
                url: '/#/order',
                className: '_NR_master-data'
              },
            ]
          },
        )
      }
      if (process.env.REACT_APP_BRAND === 'paycenter') {
        _menu.push(
          {
            label: t('master_data'),
            icon: 'pi pi-box',
            className: 'master-data',
            items: [
              {
                label: t('subscriptions'),
                icon: 'pi pi-book',
                url: '/#/subscription',
                className: '_PC_master-data'
              },
              {
                label: t('transactions'),
                icon: 'pi pi-credit-card',
                url: '/#/transaction',
                className: '_PC_master-data'
              },
              {
                label: t('customers'),
                icon: 'pi pi-users',
                url: '/#/customer',
                className: '_PC_master-data'
              },
            ]
          },
        )
      }
      setMenu(_menu)
      let _profile = [
        {
          label: t('profile'),
          icon: 'pi pi-user',
          command: () => {
            window.location.replace('/#/profile');
            window.App.forceUpdate()
          }
        },
        {
          label: t('users'),
          icon: 'pi pi-users',
          command: () => {
            window.location.replace('/#/user');
            window.App.forceUpdate()
          }
        },
        {
          label: t('operators'),
          icon: 'pi pi-briefcase',
          command: () => {
            window.location.replace('/#/operator');
            window.App.forceUpdate()
          }
        },
      ]
      if (process.env.REACT_APP_BRAND === 'felhomatrac') {
        _profile.push(
          {
            label: t('accommodations'),
            icon: 'pi pi-home',
            command: () => {
              window.location.replace('/#/accommodation');
              window.App.forceUpdate()
            }
          },
        )
      }
      if (process.env.REACT_APP_BRAND === 'netrendel') {
        _profile.push(
          {
            label: t('pointofsales'),
            icon: 'pi pi-home',
            command: () => {
              window.location.replace('/#/pointofsale');
              window.App.forceUpdate()
            }
          },
        )
      }
      if (process.env.REACT_APP_BRAND === 'paycenter') {
        _profile.push(
          {
            label: t('revolutsettings'),
            icon: 'pi pi-credit-card',
            command: () => {
              window.location.replace('/#/revolutsettings');
              window.App.forceUpdate()
            }
          },
          {
            label: t('acceptingplaces'),
            icon: 'pi pi-home',
            command: () => {
              window.location.replace('/#/acceptplace');
              window.App.forceUpdate()
            }
          },
        )
      }
      if (process.env.REACT_APP_BRAND !== 'paycenter') {
        _profile.push(
          {
            label: t('subscription'),
            icon: 'pi pi-history',
            command: () => {
              window.location.replace('/#/subscription');
              window.App.forceUpdate()
            }
          },
        )
      }
      _profile.push(
        {
          label: t('logout'),
          icon: 'pi pi-sign-out',
          command: () => {
            window.App.setState({confirmLogout: true})
          }
        },
      )
      setProfile(_profile)
      window.App.setState({rerenderNavbar: false})
    }
  }, [localStorage.getItem('adminSession'), window.App.state.rerenderNavbar])

  return (
    <nav>
      <>
        <ConfirmDialog group="declarative" visible={window.App.state.confirmLogout}
                       onHide={() => {window.App.setState({confirmLogout: false})}}
                       acceptLabel={t('yes')} rejectLabel={t('no')}
                       message={t('confirm_logout')} header={t('logout')} icon="pi pi-exclamation-triangle"
                       accept={() => {
                         window.App.logout()
                       }}
                       reject={() => {
                         window.App.setState({confirmLogout: false})
                       }}/>
        <Menubar
          style={{minHeight: '52px'}}
          start={
            <>
              <Link to={"/"}>
                {(!localStorage.getItem('adminSession') || localStorage.getItem('adminSession') === 'false' ||
                  !Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN')) &&
                    <Badge value={Cookies.get("instanceName")} severity="info"></Badge>
                }
                {(localStorage.getItem('adminSession') && localStorage.getItem('adminSession') === 'true')
                && Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN') &&
                  <Badge value={"ENGINE ROOM"} severity="danger"></Badge>
                }
              </Link>
            </>
          }
          model={menu}
          className={"p-py-0"}
          end={
            <>
              <HelpComponent type={"global"} position={"UNDER_HELP_BUTTON"}/>
              {(Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN')) &&
              <>
                {(localStorage.getItem('adminSession') && localStorage.getItem('adminSession') === 'true') &&
                <>
                  <Button type="button" className="p-button-danger p-ml-2" icon={"pi pi-star-fill"}
                          onClick={() => {
                            localStorage.setItem('adminSession', 'false')
                            document.getElementById('body').classList.remove('admin')
                            window.location.replace('/#/');
                            window.App.forceUpdate()
                          }}/>
                </>
                }
                {(!localStorage.getItem('adminSession') || localStorage.getItem('adminSession') === 'false') &&
                <>
                  <Button type="button" className="p-button-danger p-ml-2" icon={"pi pi-star"}
                          onClick={() => {
                            localStorage.setItem('adminSession', 'true')
                            document.getElementById('body').classList.add('admin')
                            window.location.replace('/#/');
                            window.App.forceUpdate()
                          }}/>
                </>
                }
              </>
              }
              <SplitButton label={t('profile')}
                           id="buttonProfile"
                           onClick={() => {
                             document.getElementById('buttonProfile').getElementsByClassName('p-splitbutton-menubutton')[0].click()
                           }}
                           icon="pi pi-user"
                           className={"p-button-success p-ml-2"}
                           model={profile}/>
            </>
          }
        />
      </>
      <HelpComponent type={"global"} position={"TOP_OF_THE_PAGE"}/>
    </nav>
  )
}
export default withTranslation('common')(NavbarComponent);
