import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import OperatorListComponent from "../components/operator/OperatorListComponent";
import OperatorFormComponent from "../components/operator/OperatorFormComponent";
import OperatorShowComponent from "../components/operator/OperatorShowComponent";

const OperatorPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('operator-data') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="OperatorPage p-component p-m-lg-4">
      <Panel>
        <OperatorListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarOperatorForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOperatorForm: false,
               })}>
        <OperatorFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOperatorForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOperatorForm: false,
               })}>
        <OperatorFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarOperatorShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarOperatorShow: false,
               })}>
        <OperatorShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(OperatorPage);
