import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";
import QRCode from "react-qr-code";

const _NR_MenuShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsMenu && window.App.state.propsMenu.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "menu/" + window.App.state.propsMenu.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('menu_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarMenuShow: false,
                        sidebarMenuForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          {formData && formData.unique_id &&
          <div className={"p-col-12 p-lg-3"}>
            <QRCode
              id={'svg'}
              size={256}
              style={{ height: "auto", maxWidth: "100%", width: "100%" }}
              value={process.env.REACT_APP_APP_PROTOCOL + Cookies.get("instanceName") + "." + process.env.REACT_APP_APP_HOST + "publicmenu?hash=" + formData.unique_id + "&systemname=" + Cookies.get("instanceName")}
              viewBox={`0 0 256 256`}
            />
            <Button type="button" label={t('download')} icon={"pi pi-download"}
                    onClick={() => {
                      let svgEl = document.getElementById('svg')
                      svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                      var svgData = svgEl.outerHTML;
                      var preface = '<?xml version="1.0" standalone="no"?>\r\n';
                      var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
                      var svgUrl = URL.createObjectURL(svgBlob);
                      var downloadLink = document.createElement("a");
                      downloadLink.href = svgUrl;
                      downloadLink.download = 'menu.svg';
                      document.body.appendChild(downloadLink);
                      downloadLink.click();
                      document.body.removeChild(downloadLink);
                    }}
                    className="p-button-secondary w-100"/>
          </div>
          }

        </div>

      </Panel>
    </>
  )
}
export default withTranslation('common')(_NR_MenuShowComponent);
