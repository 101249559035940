import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";

const MonologShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [contexts, setContexts] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsMonolog && window.App.state.propsMonolog.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/monolog-log/" + window.App.state.propsMonolog.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          if (response.data.data?.context) {
            let _contexts = []
            Object.entries(response.data.data.context).map(([key, value]) => {
              if (typeof value !== 'object') {
                _contexts.push(
                  {
                    key: key,
                    value: value
                  }
                )
              } else {
                let _responses = []
                let _value = JSON.stringify(value)
                _value.split(",").forEach(pair => {
                  const separatorIndex = pair.indexOf(":");
                  const key = pair.substring(0, separatorIndex).trim(); // Kulcs a ":" előtt
                  const value = pair.substring(separatorIndex + 1).trim(); // Érték a ":" után
                  _responses.push(
                    {
                      key: key,
                      value: value,
                    }
                  )
                });
                _contexts.push(
                  {
                    key: key,
                    value: _responses
                  }
                )
              }
            });
            setContexts(_contexts)
          }
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('monolog_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="message">{t('message')}</label>
              <span className="p-d-block p-text-bold">
                {formData.message}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="level_name">{t('level')}</label>
              <span className="p-d-block p-text-bold">
                {formData.level_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="used_system">{t('used_system')}</label>
              <span className="p-d-block p-text-bold">
                {formData.used_system}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="channel">{t('channel')}</label>
              <span className="p-d-block p-text-bold">
                {formData.channel}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="at">{t('at')}</label>
              <span className="p-d-block p-text-bold">
                {formData.at}
              </span>
            </div>
          </div>
          {contexts && contexts[0] &&
          <>
            <div className={"p-col-12"}>
              <hr/>
            </div>
            <div className={"p-col-12"}>
              <label>{t('context')}</label>
              <div className={"p-mt-2"}>
                {contexts?.map((item, index) => {
                  return (
                    <div className={"p-grid"}>
                      <div className={"p-col-4 p-text-bold"}>
                        {item.key.toString().replaceAll('{', '').replaceAll('"', '')}
                      </div>
                      <div className={"p-col-8 p-text-bold"}>
                        {(Array.isArray(item.value)) ? (
                          <>
                          {item?.value.map((item, index) => {
                            return(
                              <div className={"p-grid"}>
                                <div className={"p-col-4 p-text-bold"}>
                                  {item.key.toString().replaceAll('{', '').replaceAll('"', '')}
                                </div>
                                <div className={"p-col-8 p-text-bold"}>
                                  {item.value.toString().replaceAll('"', '').replaceAll('{', '').replaceAll('}', '')}
                                </div>
                              </div>
                            )
                          })}
                          </>
                        ):(
                          <>
                            {item.value.toString().replaceAll('"', '').replaceAll('{', '').replaceAll('}', '')}
                          </>
                        )}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
          }
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(MonologShowComponent);
