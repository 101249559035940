import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import MonologListComponent from "../components/monolog/MonologListComponent";
import MonologShowComponent from "../components/monolog/MonologShowComponent";

const MonologPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('monolog') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="MonologPage p-component p-m-lg-4">
      <div className={"p-p-3 p-p-lg-0"}>
        <Panel>
          <MonologListComponent />
        </Panel>
        <Sidebar position="right" visible={window.App.state.sidebarMonologShow} closeOnEscape={true}
                 style={{width: '780px'}}
                 onHide={() => window.App.setState({
                   sidebarMonologShow: false,
                 })}>
          <MonologShowComponent />
        </Sidebar>
      </div>
    </div>
  )
}
export default withTranslation('common')(MonologPage);
