import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {DataTable} from 'primereact/datatable';
import {Column} from 'primereact/column';
import {Button} from "primereact/button";
import {Dropdown} from "primereact/dropdown";
import axios from "axios"; import Cookies from "js-cookie";
import {InputText} from "primereact/inputtext";
import moment from "moment";

const RegistrationsListComponent = (props) => {
  const {t} = props;
  const [registrations, setRegistrations] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalRecords, setTotalRecords] = useState(0);
  const [rows, setRows] = useState(10);
  const dt = useRef(null);
  const [lazyParams, setLazyParams] = useState({
    first: 0,
    rows: rows,
    page: 0,
    sortField: 'id',
    sortOrder: -1
  });
  let loadLazyTimeout = null;
  const loadLazyData = () => {
    setLoading(true);
    if (loadLazyTimeout) {
      clearTimeout(loadLazyTimeout);
    }
    loadLazyTimeout = setTimeout(() => {
      let query = window.App.transformQueryParams(lazyParams)
      window.App.setState({rerenderRegistrationsList: false})
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/user-registration" + query,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setTotalRecords(response.data.data.total_item_count);
          setRegistrations(response.data.data.items);
          setLoading(false);
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
    }, Math.random() * 1000 + 250);
  }
  const onPage = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onSort = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    setLazyParams(_lazyParams);
  }
  const onFilter = (event) => {
    let _lazyParams = {...lazyParams, ...event};
    _lazyParams['first'] = 0;
    _lazyParams['page'] = 0;
    setLazyParams(_lazyParams);
  }
  const pager = {
    layout: 'RowsPerPageDropdown CurrentPageReport PrevPageLink NextPageLink',
    'RowsPerPageDropdown': (options) => {
      const dropdownOptions = [
        {label: 10, value: 10},
        {label: 20, value: 20},
        {label: 50, value: 50},
        {label: 200, value: 200}
      ];

      return (
        <>
          {/*<span className="p-mx-1" style={{color: 'var(--text-color)', registrationsSelect: 'none'}}>Tételek egy oldalon: </span>*/}
          <Dropdown value={options.value} options={dropdownOptions} onChange={(e) => setRows(e.target.value)}
                    appendTo={document.body}/>
        </>
      );
    },
    'CurrentPageReport': (options) => {
      return (
        <span style={{color: 'var(--text-color)', registrationsSelect: 'none', width: '120px', textAlign: 'center'}}>
                        {options.first} - {options.last} / {options.totalRecords}
        </span>
      )
    }
  };

  const actionBodyTemplate = (rowData) => {
    return (
      <React.Fragment>
        <Button icon="pi pi-eye" className="p-button-sm  p-button-secondary"
                tooltip="Megtekintés"
                tooltipOptions={{ position: 'left' }}
                onClick={() => {
                  window.App.setState({
                    sidebarRegistrationsShow: true,
                    propsRegistrations: {id: rowData.id}
                  })
                }}/>
      </React.Fragment>
    );
  }

  useEffect(() => {
    loadLazyData();
  }, [lazyParams, window.App.state.rerenderRegistrationsList === true])

  return (
    <>
      <div className={"p-grid"}>
        <div className={"p-col"}>
          <h2 className={"p-m-0"}>{t('registrations_list')}</h2>
        </div>
      </div>
      <DataTable
        ref={dt}
        size={"small"}
        emptyMessage={t('empty_message')}
        value={registrations} paginator first={lazyParams.first} totalRecords={totalRecords} onPage={onPage}
        onSort={onSort} sortField={lazyParams.sortField} sortOrder={lazyParams.sortOrder}
        onFilter={onFilter} filters={lazyParams.filters} loading={loading} lazy
        paginatorTemplate={pager} rows={rows} emptyMessage={t('empty_message')}
        autoLayout={true}
        filterDisplay="menu"
        responsiveLayout="scroll"
        stripedRows
        dataKey="id"
        selectionMode={"single"}
        onRowDoubleClick={(rowData) => {
          window.App.setState({
            sidebarRegistrationsShow: true,
            propsRegistrations: {id: rowData.data.id}
          })
        }}
      >
        <Column field="created_at" sortable header={t('at')} body={(rowData) => {
          if (rowData.created_at !== null) {
            return (
              <span>{moment.utc(rowData.created_at).local().format(t('YYYY-MM-DD HH:mm:ss'))}</span>
            )
          }
        }}></Column>
        <Column field="email" sortable header={t('email')}></Column>
        <Column field="system_name" sortable header={t('instance_name')}></Column>
        <Column body={actionBodyTemplate} className={"p-py-0 p-text-nowrap p-text-right"}></Column>
      </DataTable>
    </>
  )
}
export default withTranslation('common')(RegistrationsListComponent);
