import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _FM_PaymentmethodListComponent from "../components/_FM_paymentmethod/_FM_PaymentmethodListComponent";
import _FM_PaymentmethodFormComponent from "../components/_FM_paymentmethod/_FM_PaymentmethodFormComponent";
import _FM_PaymentmethodShowComponent from "../components/_FM_paymentmethod/_FM_PaymentmethodShowComponent";

const _FM_PaymentmethodPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('paymentmethod') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_FM_PaymentmethodPage p-component p-m-lg-4">
      <Panel>
        <_FM_PaymentmethodListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarPaymentmethodForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPaymentmethodForm: false,
               })}>
        <_FM_PaymentmethodFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarPaymentmethodShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPaymentmethodShow: false,
               })}>
        <_FM_PaymentmethodShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_FM_PaymentmethodPage);
