import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import axios from "axios"; import Cookies from "js-cookie";
import {OverlayPanel} from "primereact/overlaypanel";

import HelpComponent from "../HelpComponent";
import _FM_BerthFormComponent from "../_FM_berth/_FM_BerthFormComponent";
import {TabPanel, TabView} from "primereact/tabview";

const _FM_PaymentmethodShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opb = useRef(null);

  useEffect(() => {
    if (window.App.state.propsPaymentmethod && window.App.state.propsPaymentmethod.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "payment-method/" + window.App.state.propsPaymentmethod.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          window.App.setState({rerenderBerthList: false})
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.rerenderBerthList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('paymentmethod_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarPaymentmethodShow: false,
                        sidebarPaymentmethodForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
        </div>
        <TabView>
          <TabPanel header="HU">
            <div className={"p-grid"}>
              <div className={"p-col-12 p-lg-6"}>
                <div className="p-field p-fluid">
                  <label htmlFor="ntak_fizetesi_mod">{t('ntak_fizetesi_mod')}</label>
                  <span className="p-d-block p-text-bold">
                    {formData.ntak_fizetesi_mod}
                  </span>
                </div>
              </div>
            </div>
          </TabPanel>
        </TabView>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_FM_PaymentmethodShowComponent);
