import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _PC_SubscriptionListComponent from "../components/_PC_subscription/_PC_SubscriptionListComponent";
import _PC_SubscriptionShowComponent from "../components/_PC_subscription/_PC_SubscriptionShowComponent";

const _PC_SubscriptionPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('subscription') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_PC_SubscriptionPage p-component p-m-lg-4">
      <Panel>
        <_PC_SubscriptionListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarSubscriptionShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarSubscriptionShow: false,
               })}>
        <_PC_SubscriptionShowComponent />
      </Sidebar>

    </div>
  )
}
export default withTranslation('common')(_PC_SubscriptionPage);
