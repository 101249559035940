import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {BlockUI} from "primereact/blockui";
import QRCode from "react-qr-code";
import {InputSwitch} from "primereact/inputswitch";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";

const _NR_OrderspotFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({
    active:true
  });
  const [pointofsales, setPointofsales] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-spot/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOrderspotList: true,
            selectedOrderspotId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOrderspotForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-spot",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderOrderspotList: true,
            selectedOrderspotId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarOrderspotForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (window.App.state.propsOrderspot && window.App.state.propsOrderspot.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "order-spot/" + window.App.state.propsOrderspot.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(Cookies.get('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "point-of-sale",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setPointofsales(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }, [])

  useEffect(() => {
    if (formData && formData.point_of_sale && formData.point_of_sale.id) {
      setFormData({
        ...formData,
        point_of_sale: formData.point_of_sale.id
      })
    }
  }, [formData.point_of_sale])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('orderspot_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-8"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-4"}>
                  <Field name="short_name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"short_name"}/>
                      <label htmlFor="short_name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('short_name')}</label>
                      <span className="p-d-block">
                      <InputText id="short_name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     short_name: e.target.value
                                   })
                                 }}
                                 value={formData.short_name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-12 p-xl-12"}>
                  <Field name="insider_description" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"short_name"}/>
                      <label htmlFor="insider_description"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('insider_description')}</label>
                      <span className="p-d-block">
                      <InputText id="insider_description" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     insider_description: e.target.value
                                   })
                                 }}
                                 value={formData.insider_description}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6"}>
                  <Field name="point_of_sale" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"point_of_sale"}/>
                      <label htmlFor="point_of_sale"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('pointofsale')}</label>
                      <span className="p-inputgroup">
                      <Dropdown options={pointofsales}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"point_of_sale"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    point_of_sale: e.value
                                  })
                                }}
                                value={formData.point_of_sale}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-3 p-xl-3"}>
                  <Field name="active" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"active"}/>
                      <label htmlFor="active"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('active')}</label>
                      <span className="p-d-block">
                      <InputSwitch
                        className={"p-mt-1"}
                        checked={formData.active}
                        value={formData.active}
                        onChange={e => setFormData({...formData, active: e.target.value})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                {formData && formData.id && formData.unique_id &&
                <div className={"p-col-12 p-lg-3 p-xl-3"}>
                  <QRCode
                    size={256}
                    id={'svg'}
                    style={{ height: "auto", maxWidth: "100%", width: "100%" }}
                    value={process.env.REACT_APP_APP_PROTOCOL + Cookies.get("instanceName") + "." + process.env.REACT_APP_APP_HOST + "publicorder?hash=" + formData.unique_id + "&systemname=" + Cookies.get("instanceName")}
                    viewBox={`0 0 256 256`}
                  />
                  <Button type="button" label={t('download')} icon={"pi pi-download"}
                          onClick={() => {
                            let svgEl = document.getElementById('svg')
                            svgEl.setAttribute("xmlns", "http://www.w3.org/2000/svg");
                            var svgData = svgEl.outerHTML;
                            var preface = '<?xml version="1.0" standalone="no"?>\r\n';
                            var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
                            var svgUrl = URL.createObjectURL(svgBlob);
                            var downloadLink = document.createElement("a");
                            downloadLink.href = svgUrl;
                            downloadLink.download = 'menu.svg';
                            document.body.appendChild(downloadLink);
                            downloadLink.click();
                            document.body.removeChild(downloadLink);
                          }}
                          className="p-button-secondary w-100"/>
                </div>
                }
              </div>
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_NR_OrderspotFormComponent);
