import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Card} from "primereact/card";
import {Chart} from 'primereact/chart';
import {TabPanel, TabView} from "primereact/tabview";
import {Button} from "primereact/button";
import {Dialog} from "primereact/dialog";
import axios from "axios";
import Cookies from "js-cookie";

import _FM_AccommodationFormComponent from "../components/_FM_accommodation/_FM_AccommodationFormComponent";
import OperatorFormComponent from "../components/operator/OperatorFormComponent";
import LangselectComponent from "../components/LangselectComponent";
import ThemeselectComponent from "../components/ThemeselectComponent";

const _FM_ControlpanelPage = (props) => {

  const {t, i18n} = props;
  const [wizard, setWizard] = useState(false);
  const [wizardOperator, setWizardOperator] = useState(false);
  const [wizardAccommodation, setWizardAccommodation] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  useEffect(() => {
    document.title = t('control_panel') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "operator-data",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        if (!response.data.data.items[0]) {
          setWizardOperator(true)
          setWizard(true)
        } else {
          window.App.setState({selectedOperatorId: response.data.data.items[0].id})
          setWizardOperator(false)
          setActiveIndex(1)
          axios({
            method: 'get',
            url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation",
            params: {},
            headers: {
              'Authorization': 'Bearer ' + Cookies.get("userToken"),
              "Sys-name": Cookies.get("instanceId"),
              "Preferred-locale": Cookies.get("language")
            }
          })
            .then(response => {
              if (!response.data.data.items[0]) {
                setWizardAccommodation(true)
                setWizard(true)
              } else {
                setWizardAccommodation(false)
                setWizard(false)
              }
            })
            .catch(error => {

            })
        }
      })
      .catch(error => {

      })
    window.App.setState({
      rerenderOperatorList: false,
      rerenderAccommodationList: false,
    })
  }, [window.App.state.rerenderOperatorList === true, window.App.state.rerenderAccommodationList === true]);

  return (
    <div className="_FM_ControlpanelPage p-component p-m-lg-4">
      {(!localStorage.getItem('adminSession') || localStorage.getItem('adminSession') === 'false') ? (
        <>
          {wizard === true &&
          <Button type="button" className="p-button-warning" icon={"pi pi-cog"} id={"debugButton"} label={t('wizard')}
                  onClick={() => {
                    window.App.setState({rerenderOperatorList: true})
                  }}/>
          }
          <Dialog header={t('wizard')} visible={wizardOperator || wizardAccommodation}
                  onHide={() => {
                    setWizardOperator(false)
                    setWizardAccommodation(false)
                  }} style={{minWidth: '780px', width: '780px', maxWidth: '780px'}} draggable={false}>
            <TabView renderActiveOnly="true" scrollable={false} id="tabScroll" activeIndex={activeIndex}
                     onTabChange={(e) => {
                     }}>
              <TabPanel header={t('operator_settings')}>
                <div className={""}>
                  <OperatorFormComponent/>
                </div>
              </TabPanel>
              <TabPanel header={t('accommodation_settings')}>
                <div className={""}>
                  <_FM_AccommodationFormComponent/>
                </div>
              </TabPanel>
            </TabView>
            <hr/>
            <div className={"p-grid p-mt-2"}>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <LangselectComponent/>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center p-text-center"}>
                <ThemeselectComponent/>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center p-text-right"}>
                <Button icon="pi pi-times" label={t('close')} className="p-button-secondary"
                        onClick={() => {
                          setWizardOperator(false)
                          setWizardAccommodation(false)
                        }}/>
              </div>
            </div>
          </Dialog>
          <div className={"p-p-3 p-p-lg-0"}>
            <div className={"p-grid kpi"} style={{maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Foglalások</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        40
                      </span>
                      &nbsp;
                      foglalt éjszaka
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Bevételek</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        2 111 420 HUF
                      </span>
                      &nbsp;
                      teljes bevétel
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Kihasználtság</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        44%
                      </span>
                      &nbsp;
                      foglalások aránya
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-2"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Napi ár</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        32 000 HUF
                      </span>
                      &nbsp;
                      átlagos ár
                    </div>
                  </div>
                </Card>
              </div>
              <div className={"p-col-12 p-lg-4"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-9 p-col-align-center"}>
                      <small className={"p-text-uppercase"}>Csatornák</small>
                      <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                    </div>
                    <div className={"p-col-3 p-col-align-center p-text-right"}>
                      <i className={"pi pi-chart-bar"} style={{'fontSize': '1.5em'}}></i>
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        23
                      </span>
                      &nbsp;
                      Airbnb
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'red'}}>
                        <i className={"pi pi-arrow-down"}></i>
                        &nbsp;
                        20
                      </span>
                      &nbsp;
                      Booking.com
                    </div>
                    <div className={"p-col-4"}>
                      <span className={"p-text-bold"} style={{color: 'green'}}>
                        <i className={"pi pi-arrow-up"}></i>
                        &nbsp;
                        80
                      </span>
                      &nbsp;
                      Szallas.hu
                    </div>
                  </div>
                </Card>
              </div>
            </div>

            <div className={"p-grid p-mt-0 chart"}  style={{maxWidth: '1920px', marginLeft: 'auto', marginRight: 'auto'}}>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Csatonák</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Direkt foglalás', 'Airbnb', 'Booking.com', 'Szallas.hu'],
                      datasets: [
                        {
                          data: [2140, 540, 325, 702],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Bevételi arány</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Foglalások', 'Szolgáltatások', 'Egyéb'],
                      datasets: [
                        {
                          data: [222140, 23540, 1325],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
              <div className={"p-col-12 p-lg-4 p-col-align-center"}>
                <Panel>
                  <small className={"p-text-uppercase"}>Soba típusok</small>
                  <h3 className={"p-m-0 p-p-0 p-text-secondary"}> 2024. november</h3>
                  <Chart type="doughnut" data={
                    {
                      labels: ['Economy', 'Standard', 'Superior', 'Apartman', 'Suite'],
                      datasets: [
                        {
                          data: [4, 12, 1, 4, 5],
                        }
                      ]
                    }
                  } options={
                    {
                      animation: false,
                      cutout: '30%',
                      plugins: {
                        legend: {
                          labels: {
                            usePointStyle: true
                          }
                        }
                      }
                    }
                  } className="w-full"/>
                </Panel>
              </div>
            </div>
          </div>
        </>
      ):(
        <>
        </>
      )}
    </div>
  )
}
export default withTranslation('common')(_FM_ControlpanelPage);
