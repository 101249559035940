import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _NR_PointofsaleListComponent from "../components/_NR_pointofsale/_NR_PointofsaleListComponent";
import _NR_PointofsaleFormComponent from "../components/_NR_pointofsale/_NR_PointofsaleFormComponent";
import _NR_PointofsaleShowComponent from "../components/_NR_pointofsale/_NR_PointofsaleShowComponent";

const _NR_PointofsalePage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('pointofsale') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_NR_PointofsalePage p-component p-m-lg-4">
      <Panel>
        <_NR_PointofsaleListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarPointofsaleForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPointofsaleForm: false,
               })}>
        <_NR_PointofsaleFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarPointofsaleShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarPointofsaleShow: false,
               })}>
        <_NR_PointofsaleShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_NR_PointofsalePage);
