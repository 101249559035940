import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Dropdown} from "primereact/dropdown";
import Cookies from "js-cookie";

const ThemeselectComponent = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    if(!Cookies.get('theme')) {
      Cookies.set('theme', 'light', {
        expires: 365,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
    }
  }, [])

  return (
    <Dropdown options={[
      {
        name: t('dark'),
        value: 'dark'
      },
      {
        name: t('light'),
        value: 'light'
      },
    ]}
              optionLabel="name"
              optionValue="value"
              id={"theme"}
              emptyMessage={t('empty_message')}
              onChange={(e) => {
                Cookies.set('theme', e.value.toString(), {
                  expires: 365,
                  secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
                  sameSite: 'Strict',
                  path: '/',
                  domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
                });
                if(e.value === 'dark') {
                  document.getElementById('body').classList.add('dark-theme')
                  const themeLink = document.createElement('link');
                  themeLink.rel = 'stylesheet';
                  themeLink.href = '/dark.css';
                  document.head.appendChild(themeLink);
                } else {
                  document.getElementById('body').classList.remove('dark-theme')
                  const link = document.querySelector(`link[href="/dark.css"]`);
                  if (link) {
                    link.parentNode.removeChild(link);
                  }
                }
                window.App.forceUpdate()
              }}
              value={Cookies.get('theme')}
    />
  )
}
export default withTranslation('common')(ThemeselectComponent);
