import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Tooltip} from 'primereact/tooltip';
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import moment from "moment";
import axios from "axios"; import Cookies from "js-cookie";

import HelpComponent from "../HelpComponent";
import _PC_AcceptingplaceFormComponent from "../_PC_acceptingplace/_PC_AcceptingplaceFormComponent";

const _PC_SubscriptionShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opa = useRef(null);

  useEffect(() => {
    if (window.App.state.propsSubscription && window.App.state.propsSubscription.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "subscription/" + window.App.state.propsSubscription.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
    window.App.setState({rerenderAcceptingplaceList: false})
  }, [window.App.state.rerenderAcceptingplaceList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('subscription_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            {/*<Button type="button" aria-label={t('edit')}*/}
            {/*        icon={"pi pi-pencil"}*/}
            {/*        onClick={() => {*/}
            {/*          window.App.setState({*/}
            {/*            sidebarSubscriptionShow: false,*/}
            {/*            sidebarSubscriptionForm: true,*/}
            {/*          })*/}
            {/*        }}*/}
            {/*        className="p-button-warning p-ml-2">*/}
            {/*</Button>*/}
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="amount">{t('amount')}</label>
              <span className="p-d-block p-text-bold">
                {formData.amount} {formData.currency}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="accepting_place">{t('accepting_place')}</label>
              <span className="p-d-block p-text-bold">
                {formData.accepting_place?.name}
                &nbsp;
                <i className={"pi pi-window-maximize p-ml-2"}
                   onClick={(e) => {
                     opa.current.toggle(e)
                     window.App.setState({
                       propsAcceptingplace: {id: formData.accepting_place?.id}
                     })
                   }}
                ></i>
                <OverlayPanel ref={opa} showCloseIcon>
                  <_PC_AcceptingplaceFormComponent />
                </OverlayPanel>
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="description">{t('description')}</label>
              <span className="p-d-block p-text-bold">
                {formData.description}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="status">{t('status')}</label>
              <span className="p-d-block p-text-bold">
                {formData.status}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="frequency">{t('frequency')}</label>
              <span className="p-d-block p-text-bold">
                {formData.frequency}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="external_reference_id">{t('external_reference_id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.external_reference_id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="payment_provider">{t('payment_provider')}</label>
              <span className="p-d-block p-text-bold">
                {formData.payment_provider}
              </span>
            </div>
          </div>
          <div className={"p-col-9"}>
            <div className="p-field p-fluid">
              <label htmlFor="success_transaction_callback_url">{t('success_transaction_callback_url')}</label>
              <span className="p-d-block p-text-bold">
                {formData.success_transaction_callback_url}
              </span>
            </div>
          </div>
          <div className={"p-col-3 p-col-align-center"}>
            <Button icon="pi pi-check" className="p-button-sm  p-button-success w-100"
                    label={t('set_paid')}
            />
          </div>
          <div className={"p-col-9"}>
            <div className="p-field p-fluid">
              <label htmlFor="failed_transaction_callback_url">{t('failed_transaction_callback_url')}</label>
              <span className="p-d-block p-text-bold">
                {formData.failed_transaction_callback_url}
              </span>
            </div>
          </div>
          <div className={"p-col-3 p-col-align-center"}>
            <Button icon="pi pi-times" className="p-button-sm  p-button-danger w-100"
                    label={t('set_cancelled')}
            />
          </div>

          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="start_date">{t('start_date')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.start_date).local().format(t('YYYY-MM-DD'))}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="next_payment_date">{t('next_payment_date')}</label>
              <span className="p-d-block p-text-bold">
                {moment.utc(formData.next_payment_date).local().format(t('YYYY-MM-DD'))}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="last_report_attempt">
                <i className={"pi pi-info-circle p-mr-2 tt_last_report_attempt"}
                   data-pr-tooltip="Last change report to linked system"></i>
                <Tooltip target=".tt_last_report_attempt"/>
                {t('last_report_attempt')}
              </label>
              <span className="p-d-block p-text-bold">
                {formData.last_report_attempt &&
                <>
                  {moment.utc(formData.last_report_attempt).local().format(t('YYYY-MM-DD'))}
                </>
                }
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-3"}>
            <div className="p-field p-fluid">
              <label htmlFor="last_success_status_report">
                <i className={"pi pi-info-circle p-mr-2 tt_last_success_status_report"}
                   data-pr-tooltip="Last success change report to linked system"></i>
                <Tooltip target=".tt_last_success_status_report"/>
                {t('last_success_status_report')}
              </label>
              <span className="p-d-block p-text-bold">
                {formData.last_success_status_report &&
                <>
                  {moment.utc(formData.last_success_status_report).local().format(t('YYYY-MM-DD'))}
                </>
                }
              </span>
            </div>
          </div>
        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_PC_SubscriptionShowComponent);
