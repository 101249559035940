import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Card} from "primereact/card";
import {Button} from "primereact/button";
import {Badge} from 'primereact/badge';
import {Link} from "react-router-dom";
import {Dialog} from "primereact/dialog";
import axios from "axios";
import Cookies from "js-cookie";
import moment from "moment";
import {ConfirmDialog} from "primereact/confirmdialog";

const SubscriptionPage = (props) => {

  const {t, i18n} = props;
  const [loading, setLoading] = useState(false);
  const [subscriptionplan, setSubscriptionplan] = useState([]);
  const [subscriptionplanActive, setSubscriptionplanActive] = useState();
  const [paymentDialog, setPaymentDialog] = useState(false);
  const [paymentLink, setPaymentLink] = useState(null);

  const planDescription = (planId) => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "subscription-plan/" + planId,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        return (
          response.data.data.public_description
        )
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }

  const subscriptions = () => {
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system/settings/subscription",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setSubscriptionplanActive(response.data.data)
        setPaymentLink(response.data.data?.meta?.revolut_first_pay_link)
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "subscription-plan",
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
          .then(response => {
            setSubscriptionplan(response.data.data.items)
            window.App.setState({rerenderSubscriptionPage: false})
          })
          .catch(error => {
            window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          })
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }

  useEffect(() => {
    document.title = t('subscription') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
    subscriptions()
  }, [window.App.state.rerenderSubscriptionPage === true]);

  return (
    <div className="SubscriptionPage p-component p-my-lg-4 p-mx-lg-auto">
      {subscriptionplanActive &&
      <Panel>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            <h2 className={"p-m-0"}>{t('active_subscriptionplan')}</h2>
          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>

          </div>
        </div>
        <div className={"p-grid"} style={{justifyContent: 'center', alignItems: 'stretch'}}>

          <div className={"p-col plan"}>
            <Card className={""}>
              <div className={"p-card-title"}>
                {subscriptionplanActive.subscription_plan.name}
                &nbsp;
                {subscriptionplanActive.status && subscriptionplanActive.status === 'ACTIVE' &&
                <Badge value={t('active')} severity="success"></Badge>
                }
                {subscriptionplanActive.status && subscriptionplanActive.status === 'CANCELLED' &&
                <Badge value={t('cancelled')} severity="danger"></Badge>
                }
                {subscriptionplanActive.status && subscriptionplanActive.status === 'PENDING' &&
                <Badge value={t('pending')} severity="warning"></Badge>
                }
              </div>
              <div className={"p-card-subtitle"}>
                {subscriptionplanActive.subscription_plan.amount + " " + subscriptionplanActive.subscription_plan.currency + " / " + t(subscriptionplanActive.subscription_plan.frequency.toLowerCase())}
              </div>
              <p className={"p-text-bold"}>
                {planDescription(subscriptionplanActive.subscription_plan.id)} Lorem ipsum dolor sit amet, consectetur
                adipiscing elit, sed do eiusmod
                tempor incididunt ut labore et dolore magna aliqua.
              </p>
              <div className={"p-grid"}>
                <div className={"p-col p-text-center"}>
                  {t('subscription_start_date')}:
                  <span className={"p-d-block p-text-bold"}>
                    {moment.utc(subscriptionplanActive.subscription_start_date).local().format('YYYY-MM-DD')}
                  </span>
                </div>
                <div className={"p-col p-text-center"}>
                  {subscriptionplanActive.subscription_plan.amount > 0 && subscriptionplanActive.status && subscriptionplanActive.status === 'ACTIVE' ? (
                    <>{t('next_payment_date')}:</>
                  ) : (
                    <>{t('subscription_end_date')}:</>
                  )}
                  <span className={"p-d-block p-text-bold"}>
                    {moment.utc(subscriptionplanActive.next_payment_date).local().format('YYYY-MM-DD')}
                  </span>
                </div>
              </div>
              <div className={"p-text-center p-mt-3"}>
                {subscriptionplanActive.status && subscriptionplanActive.status === 'PENDING' && subscriptionplanActive.subscription_plan.amount !== 0 &&
                <Link to={paymentLink} className={"p-button p-button-sm p-button-success p-mr-2"} target="_blank"
                      onClick={() => {
                        clearInterval(window.intervalSubscription)
                        window.intervalSubscription = setInterval(function () {
                          window.App.setState({rerenderSubscriptionPage: true})
                        }, 30000)
                      }}>
                  {t('thank_you_for_subscription_button')}
                </Link>
                }
                {subscriptionplanActive.status && subscriptionplanActive.status !== 'CANCELLED' &&
                <>
                  <Button icon="pi pi-trash" className="p-button-sm  p-button-danger"
                          label={t('cancel_subscription')}
                          onClick={() => {
                            window.App.setState({confirmSubscriptionCancel: true})
                          }}/>
                  <ConfirmDialog group="declarative" visible={window.App.state.confirmSubscriptionCancel}
                                 acceptLabel={t('yes')} rejectLabel={t('no')}
                                 onHide={() => {
                                   window.App.setState({confirmSubscriptionCancel: false})
                                 }}
                                 message={t('confirm_subscription_cancel')} header={t('confirm_subscription_cancel')}
                                 icon="pi pi-exclamation-triangle"
                                 accept={() => {
                                   axios({
                                     method: 'get',
                                     url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system/settings/subscription/cancel",
                                     params: {},
                                     headers: {
                                       'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                       "Sys-name": Cookies.get("instanceId"),
                                       "Preferred-locale": Cookies.get("language")
                                     }
                                   })
                                     .then(response => {
                                       subscriptions()
                                       window.App.toastShow('success', t('success'), response.data.message)
                                     })
                                     .catch(error => {
                                       window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                                     })
                                 }}
                                 reject={() => {
                                   window.App.setState({confirmSubscriptionCancel: false})
                                 }}/>
                </>
                }
              </div>
            </Card>
          </div>
        </div>
      </Panel>
      }
      {subscriptionplan && subscriptionplan[0] &&
      <div className={"p-mt-4"}>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-6 p-col-align-center"}>
            <h2 className={"p-m-0"}>{t('subscriptionplans')}</h2>
          </div>
          <div className={"p-col-12 p-lg-6 p-col-align-center p-text-right"}>

          </div>
        </div>
        <div className={"p-grid"} style={{justifyContent: 'center', alignItems: 'stretch'}}>
          {subscriptionplan?.map((plan, index) => {
            return (
              <div className={"p-col plan"}>
                <Card className={""}>
                  <div className={"p-grid"}>
                    <div className={"p-col-3 p-text-center p-col-align-center"}>
                      <h1 className={"p-my-0"} style={{fontSize: '4rem'}}>
                        {index + 1}.
                      </h1>
                    </div>
                    <div className={"p-col  p-col-align-center"}>
                      <div className={"p-card-title"}>{plan.name}</div>
                      <div className={"p-card-subtitle"}>
                        {plan.amount + " " + plan.currency + " / " + t(plan.frequency.toLowerCase())}
                      </div>
                    </div>
                  </div>
                  <div className={"plan-content"}>
                    <p className={"p-text-bold"}>
                      {planDescription(plan.id)} Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod
                      tempor incididunt ut labore et dolore magna aliqua.
                    </p>

                    <ul>
                      <li>
                        <i className={"pi pi-check"}></i> Lorem ipsum dolor
                      </li>
                      <li>
                        <i className={"pi pi-check"}></i> Lorem ipsum dolor
                      </li>
                      <li>
                        <i className={"pi pi-check"}></i> Lorem ipsum dolor
                      </li>
                      <li>
                        <i className={"pi pi-check"}></i> Lorem ipsum dolor
                      </li>
                      <li>
                        <i className={"pi pi-check"}></i> Lorem ipsum dolor
                      </li>
                    </ul>
                    <div className={"p-text-center p-mt-4"}>
                      {subscriptionplanActive && subscriptionplanActive.subscription_plan && subscriptionplanActive.subscription_plan.id === plan.id ? (
                        <Button icon="pi pi-check" className="p-button-sm  p-button-success p-mx-auto"
                                label={t('start_subscription')} disabled/>
                      ) : (
                        <>
                          <Button icon="pi pi-check" className="p-button-sm  p-button-success p-mx-auto"
                                  label={t('start_subscription')}
                                  onClick={() => {
                                    window.App.setState({confirmSubscriptionStart: true})
                                  }}/>
                          <ConfirmDialog group="declarative" visible={window.App.state.confirmSubscriptionStart}
                                         acceptLabel={t('yes')} rejectLabel={t('no')}
                                         onHide={() => {
                                           window.App.setState({confirmSubscriptionStart: false})
                                         }}
                                         message={t('confirm_subscription_start')}
                                         header={t('confirm_subscription_start')}
                                         icon="pi pi-exclamation-triangle"
                                         accept={() => {
                                           axios({
                                             method: 'get',
                                             url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system/settings/subscription/subscribe/" + plan.id,
                                             params: {},
                                             headers: {
                                               'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                               "Sys-name": Cookies.get("instanceId"),
                                               "Preferred-locale": Cookies.get("language")
                                             }
                                           })
                                             .then(response => {
                                               window.App.toastShow('success', t('success'), response.data.message)
                                               subscriptions()
                                               if (response.data.data?.subscription_plan?.amount !== 0) {
                                                 setPaymentDialog(true)
                                                 setPaymentLink(response.data.data?.meta?.revolut_first_pay_link)
                                               }
                                               clearInterval(window.intervalSubscription)
                                               window.intervalSubscription = setInterval(function () {
                                                 window.App.setState({rerenderSubscriptionPage: true})
                                               }, 30000)
                                             })
                                             .catch(error => {
                                               window.App.toastShow('error', t('error'), error.toString(), '', error.response);
                                             })
                                         }}
                                         reject={() => {
                                           window.App.setState({confirmSubscriptionStart: false})
                                         }}/>
                        </>
                      )}
                    </div>

                  </div>
                </Card>
              </div>
            )
          })}
        </div>
      </div>
      }
      <Dialog header={t('thank_you_for_subscription_header')}
              visible={paymentDialog}
              style={{maxWidth: '400px'}}
              onHide={() => {
                setPaymentDialog(false)
              }}>
        <p>{t('thank_you_for_subscription_paragraph')}</p>
        <Link to={paymentLink} className={"p-button p-button-success p-button-lg p-d-block w-100"} target="_blank"
              onClick={() => {
                setPaymentDialog(false)
              }}>
          {t('thank_you_for_subscription_button')}
        </Link>
      </Dialog>
    </div>
  )
}
export default withTranslation('common')(SubscriptionPage);
