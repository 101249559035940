import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios";
import Cookies from "js-cookie";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {InputSwitch} from "primereact/inputswitch";
import {BlockUI} from "primereact/blockui";
import {Password} from "primereact/password";

import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";
import {Message} from "primereact/message";

const UsersFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({
    roles: []
  });
  const [rolesHierarchy, setRolesHierarchy] = useState([]);
  const [rolesGroups, setRolesGroups] = useState([]);
  const [roles, setRoles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.put(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderUserList: true,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderUserList: true,
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarUserForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (window.App.state.propsUser && window.App.state.propsUser.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/" + window.App.state.propsUser.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(Cookies.get('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/roles/hierarchy",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setRolesHierarchy(response.data.data)
        let _roleGroups = []
        Object.entries(response.data.data).map(function ([role, permissuon]) {
          _roleGroups.push(role)
        })
        setRolesGroups(_roleGroups)
        axios({
          method: 'get',
          url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "user/roles",
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
          .then(response => {
            setLoading(false)
            setRoles(response.data.data)
          })
          .catch(error => {
            window.App.toastShow('error', t('error'), error.toString(), '', error.response);
            setLoading(false)
          })
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        setLoading(false)
      })
  }, [])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('user_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"email"}/>
                      <label htmlFor="email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('email')}</label>
                      <span className="p-d-block">
                      <InputText id="email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     email: e.target.value
                                   })
                                 }}
                                 value={formData.email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="contact_email" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"contact_email"}/>
                      <label htmlFor="contact_email"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_email')}</label>
                      <span className="p-d-block">
                      <InputText id="contact_email" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_email: e.target.value
                                   })
                                 }}
                                 value={formData.contact_email}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="contact_phone" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"contact_phone"}/>
                      <label htmlFor="contact_phone"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('contact_phone')}</label>
                      <span className="p-d-block">
                      <InputText id="contact_phone" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     contact_phone: e.target.value
                                   })
                                 }}
                                 value={formData.contact_phone}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="plain_password" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"plain_password"}/>
                      <label htmlFor="plain_password"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('new_password')}</label>
                      <span className="p-d-block">
                      <Password id="plain_password" {...input}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    plain_password: e.target.value
                                  })
                                }}
                                value={formData.plain_password}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="repeat_password" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"repeat_password"}/>
                      <label htmlFor="repeat_password"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('password_check')}</label>
                      <span className="p-d-block">
                      <Password id="repeat_password" {...input}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    repeat_password: e.target.value
                                  })
                                }}
                                value={formData.repeat_password}
                                className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
            </Panel>
            <>
              <Panel className={"p-mt-3"}>
                <div className={"p-grid"}>
                  <div className={"p-col"}>
                    <h2 className={"p-m-0"}>{t('roles')}</h2>
                  </div>
                  <div className={"p-col p-text-right p-col-align-center"}>

                  </div>
                </div>
                <div className={"p-mt-2"}>
                  {formData.roles.toString().includes("ROLE_SUPER_ADMIN") ? (
                    <>
                      <Message severity={"warn"} className={"p-d-block p-my-1"}
                               text={t('user_is_superadmin')}/>
                    </>
                  ) : formData.roles.toString().includes("ROLE_ADMIN") ? (
                    <>
                      <Message severity={"info"} className={"p-d-block p-my-1"}
                               text={t('user_is_admin')}/>
                    </>
                  ) : (
                    <></>
                  )}
                  {Object.entries(rolesHierarchy).map(function ([role, permissions]) {
                    return (
                      <div className={"p-d-block"}>
                        <>
                          <h3>{role}</h3>
                          {permissions.map(function (permission) {
                            let _disabled = true
                            let _checked = false
                            if(formData.roles.toString().includes('ROLE_SUPER_ADMIN')) {
                              _checked = true
                            }
                            if(role.includes('ROLE_ADMIN') && formData.roles.toString().includes('ROLE_ADMIN')) {
                              _checked = true
                            }
                            if(role.includes('ROLE_API_USER') && (formData.roles.toString().includes('ROLE_ADMIN') || formData.roles.toString().includes('ROLE_API_USER'))) {
                              _checked = true
                            }
                            if(roles.toString().includes(permission) && !formData.roles.toString().includes('_ADMIN')) {
                              _disabled = false
                            }
                            if(formData.roles.toString().includes(permission)) {
                              _checked = true
                            }
                            if (!rolesGroups.toString().includes(permission.toUpperCase())) {
                              return (
                                <>
                                  <div className={"p-grid"}>
                                    <div className={"p-col p-lg-2"}>
                                      <InputSwitch
                                        className={"p-mt-1"}
                                        name={permission}
                                        value={permission}
                                        checked={_checked}
                                        disabled={_disabled}
                                        onChange={(e) => {
                                          if (formData.roles.toString().includes(e.target.name)) {
                                            let _roles = formData.roles
                                            const index = _roles.indexOf(e.target.name);
                                            if (index !== -1) {
                                              _roles.splice(index, 1);
                                            }
                                            setFormData({
                                              ...formData,
                                              roles: _roles
                                            })
                                          } else {
                                            setFormData({
                                              ...formData,
                                              roles: [
                                                ...formData.roles, e.target.name
                                              ]
                                            })
                                          }
                                        }}/>
                                    </div>
                                    <div className={"p-col"}>
                                      {t(permission).toLowerCase()}
                                    </div>
                                  </div>
                                </>
                              )
                            }
                          })}
                        </>
                      </div>
                    )
                  })}


                  {/*    <h3>{t('ROLE_ADMIN')}</h3>*/}
                  {/*    {roles.map(function (role) {*/}
                  {/*      let _disabled = false*/}
                  {/*      if (formData.roles.toString().includes("ROLE_ADMIN")) {*/}
                  {/*        _disabled = true*/}
                  {/*      }*/}
                  {/*      let _checked = false*/}
                  {/*      if (formData.roles.toString().includes(role) || formData.roles.toString().includes("ROLE_ADMIN")) {*/}
                  {/*        _checked = true*/}
                  {/*      }*/}
                  {/*      return (*/}
                  {/*        <>*/}
                  {/*          <div className={"p-grid"}>*/}
                  {/*            <div className={"p-col p-lg-2"}>*/}
                  {/*              <InputSwitch*/}
                  {/*                className={"p-mt-1"}*/}
                  {/*                name={role}*/}
                  {/*                value={role}*/}
                  {/*                disabled={_disabled}*/}
                  {/*                checked={_checked}*/}
                  {/*                onChange={(e) => {*/}
                  {/*                  if (formData.roles.toString().includes(e.target.name)) {*/}
                  {/*                    let _roles = formData.roles*/}
                  {/*                    const index = _roles.indexOf(e.target.name);*/}
                  {/*                    if (index !== -1) {*/}
                  {/*                      _roles.splice(index, 1);*/}
                  {/*                    }*/}
                  {/*                    setFormData({*/}
                  {/*                      ...formData,*/}
                  {/*                      roles: _roles*/}
                  {/*                    })*/}
                  {/*                    console.log(formData.roles)*/}
                  {/*                  } else {*/}
                  {/*                    setFormData({*/}
                  {/*                      ...formData,*/}
                  {/*                      roles: [*/}
                  {/*                        ...formData.roles, e.target.name*/}
                  {/*                      ]*/}
                  {/*                    })*/}
                  {/*                    console.log(formData.roles)*/}
                  {/*                  }*/}
                  {/*                }}/>*/}
                  {/*            </div>*/}
                  {/*            <div className={"p-col"}>*/}
                  {/*              {t(role).toLowerCase()}*/}
                  {/*            </div>*/}
                  {/*          </div>*/}
                  {/*        </>*/}
                  {/*      )*/}
                  {/*    })}*/}
                  {/*  </>*/}
                  {/*)}*/}


                  {/*{rolesHierarchy && formData.roles &&*/}
                  {/*<>*/}
                  {/*  {Object.entries(rolesHierarchy).map(([role, permissions]) => (*/}
                  {/*    <div className={"p-d-block"}>*/}
                  {/*      <h3>{role}</h3>*/}
                  {/*      <>*/}
                  {/*        {permissions.map(function (permission) {*/}
                  {/*          let _disabled = false*/}
                  {/*          if (formData.roles.toString().includes(role)) {*/}
                  {/*            _disabled = true*/}
                  {/*          }*/}
                  {/*          let _checked = false*/}
                  {/*          if (formData.roles.toString().includes(permission) || formData.roles.toString().includes(role)) {*/}
                  {/*            _checked = true*/}
                  {/*          }*/}
                  {/*          if (!rolesGroups.toString().includes(permission.toUpperCase())) {*/}
                  {/*            if (roles.toString().includes(permission)) {*/}
                  {/*              return (*/}
                  {/*                <div className={"p-grid"}>*/}
                  {/*                  <div className={"p-col p-lg-2"}>*/}
                  {/*                    <InputSwitch*/}
                  {/*                      disabled={_disabled}*/}
                  {/*                      className={"p-mt-1"}*/}
                  {/*                      name={role}*/}
                  {/*                      value={role}*/}
                  {/*                      checked={_checked}*/}
                  {/*                      onChange={(e) => {*/}

                  {/*                      }}/>*/}
                  {/*                  </div>*/}
                  {/*                  <div className={"p-col"}>*/}
                  {/*                    {t(permission).toLowerCase()}*/}
                  {/*                  </div>*/}
                  {/*                </div>*/}
                  {/*              )*/}
                  {/*            } else {*/}
                  {/*              return (*/}
                  {/*                <div className={"p-grid"}>*/}
                  {/*                  <div className={"p-col p-lg-2"}>*/}
                  {/*                    <InputSwitch*/}
                  {/*                      className={"p-mt-1"}*/}
                  {/*                      name={role}*/}
                  {/*                      value={role}*/}
                  {/*                      checked={_checked}*/}
                  {/*                      disabled*/}
                  {/*                    />*/}
                  {/*                  </div>*/}
                  {/*                  <div className={"p-col"}>*/}
                  {/*                    {t(permission).toLowerCase()}*/}
                  {/*                  </div>*/}
                  {/*                </div>*/}
                  {/*              )*/}
                  {/*            }*/}
                  {/*          }*/}
                  {/*        })}*/}
                  {/*      </>*/}
                  {/*    </div>*/}
                  {/*  ))}*/}
                  {/*</>*/}
                  {/*}*/}
                </div>
              </Panel>
            </>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(UsersFormComponent);
