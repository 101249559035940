import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Form, Field} from 'react-final-form';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import {classNames} from 'primereact/utils';
import {InputText} from 'primereact/inputtext';
import {Button} from 'primereact/button';
import {Dropdown} from "primereact/dropdown";
import {Sidebar} from "primereact/sidebar";
import {InputNumber} from "primereact/inputnumber";
import {BlockUI} from "primereact/blockui";

import _FM_AccommodationFormComponent from "../_FM_accommodation/_FM_AccommodationFormComponent";
import HelpComponent from "../HelpComponent";
import FormLocker from "../../util/FormLocker";

const _FM_CategoryFormComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [accommodation, setAccommodation] = useState([]);
  const [accommodationCountry, setAccommodationCountry] = useState('');
  const [ntakKategoria, setNtakKategoria] = useState([]);
  const [loading, setLoading] = useState(false);
  const [locking, setLocking] = useState(false);

  const validate = (data) => {
    let errors = {};
    return errors;
  };
  const onSubmit = (data, form) => {
    setLoading(true)
    if (formData && formData.id) {
      axios.patch(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + formData.id,
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data);
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderCategoryList: true,
            selectedCategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarCategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    } else {
      axios.post(process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category",
        formData,
        {
          params: {},
          headers: {
            'Authorization': 'Bearer ' + Cookies.get("userToken"),
            "Sys-name": Cookies.get("instanceId"),
            "Preferred-locale": Cookies.get("language")
          }
        })
        .then(response => {
          setFormData(response.data.data)
          setLoading(false)
          window.App.toastShow('success', t('success'), response.data.message)
          window.App.setState({
            rerenderCategoryList: true,
            selectedCategoryId: response.data.data.id
          })
          if (props.closeForm === true) {
            window.App.setState({
              sidebarCategoryForm: false,
            })
          }
        })
        .catch(error => {
          setLoading(false)
          window.App.toastShow('error', t('error'), error.response.data.message, '', error.response)
        })
    }
    form.restart();
  };
  const isFormFieldValid = (meta) => !!(meta.touched && meta.error);
  const getFormErrorMessage = (meta) => {
    return isFormFieldValid(meta) && <small className="p-error">{meta.error}</small>;
  };

  FormLocker(() => {
    if (formData && formData.id && formData.class_name) {
      window.App.formLock(formData.class_name, formData.id)
    }
  }, 1000);

  useEffect(() => {
    if (window.App.state.propsCategory && window.App.state.propsCategory.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-category/" + window.App.state.propsCategory.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if (response.data && response.data.data && response.data.data.under_edit_from && response.data.data.under_edit_by !== Number(Cookies.get('userId'))) {
            setLocking(true)
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "dictionary/ntak/kategoria",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setNtakKategoria(response.data.data);
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
    axios({
      method: 'get',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accommodation",
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
      .then(response => {
        setAccommodation(response.data.data.items);
      })
      .catch(error => {
        window.App.toastShow('error', t('error'), error.toString(), '', error.response);
      })
  }, [window.App.state.selectedAccommodationId])

  useEffect(() => {
    setFormData({
      ...formData,
      accommodation: window.App.state.selectedAccommodationId
    })
  }, [window.App.state.selectedAccommodationId])

  useEffect(() => {
    if (formData && formData.accommodation && formData.accommodation.id) {
      setFormData({
        ...formData,
        accommodation: formData.accommodation.id
      })
    }
    let country = accommodation.filter(item => item.id === formData.accommodation)
    if(country && country[0] && country[0].country) {
      setAccommodationCountry(country[0].country)
    }
  }, [formData.accommodation])

  return (
    <>
      <BlockUI blocked={locking}>
        <Form onSubmit={onSubmit} initialValues={formData}
              validate={validate} render={({handleSubmit}) => (
          <form onSubmit={handleSubmit} className="">
            <Panel className={"p-mt-3"}>
              <div className={"p-grid"}>
                <div className={"p-col"}>
                  <h2 className={"p-m-0"}>{t('category_form')}</h2>
                </div>
                <div className={"p-col p-text-right p-col-align-center"}>
                  <Button type="submit" aria-label={t('save_and_close')}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: true}
                          }}
                          className="p-button-success p-ml-2">
              <span>
                <i className={"pi pi-save"}></i>&nbsp;&nbsp;<i className={"pi pi-backward"}></i>
              </span>
                  </Button>
                  <Button type="submit" aria-label={t('save')} icon={"pi pi-save"}
                          loading={loading}
                          onClick={() => {
                            props = {...props, closeForm: false}
                          }}
                          className="p-button-success p-ml-2"/>
                </div>
              </div>
              <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
              <div className={"p-grid"}>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="name" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"name"}/>
                      <label htmlFor="name"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('name')}</label>
                      <span className="p-d-block">
                      <InputText id="name" {...input}
                                 onChange={(e) => {
                                   setFormData({
                                     ...formData,
                                     name: e.target.value
                                   })
                                 }}
                                 value={formData.name}
                                 className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="accommodation" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"accommodation"}/>
                      <label htmlFor="accommodation"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('accommodation')}</label>
                      <span className="p-inputgroup">
                      <Dropdown options={accommodation}
                                optionLabel="name"
                                optionValue="id"
                                filter
                                id={"accommodation"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    accommodation: e.value
                                  })
                                }}
                                value={formData.accommodation}
                                showClear
                      />
                      <Button type="button" className={"p-button"}
                              onClick={() => {
                                window.App.setState({
                                  sidebarAccommodationForm: true,
                                  propsAccommodation: {id: null}
                                })
                              }}>
                        <i className={"pi pi-window-maximize"}></i>
                      </Button>
                      <Sidebar position="right" visible={window.App.state.sidebarAccommodationForm} closeOnEscape={true}
                               style={{width: '780px'}}
                               onHide={() => window.App.setState({
                                 sidebarAccommodationForm: false,
                               })}>
                        <_FM_AccommodationFormComponent/>
                      </Sidebar>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-3"}>
                  <Field name="number_of_double_beds" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"number_of_double_beds"}/>
                      <label htmlFor="number_of_double_beds"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_double_beds')}</label>
                      <span className="p-d-block">
                      <InputNumber id="number_of_double_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_double_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_double_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
                <div className={"p-col-12 p-lg-3"}>
                  <Field name="number_of_single_beds" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"number_of_double_beds"}/>
                      <label htmlFor="number_of_double_beds"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('number_of_single_beds')}</label>
                      <span className="p-d-block">
                      <InputNumber id="number_of_single_beds" {...input}
                                   locale="hu-HU"
                                   onChange={(e) => {
                                     setFormData({
                                       ...formData,
                                       number_of_single_beds: e.value
                                     })
                                   }}
                                   value={formData.number_of_single_beds}
                                   className={classNames({'p-invalid': isFormFieldValid(meta)})}/>
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </div>
              {accommodationCountry && accommodationCountry === 'HU' &&
              <>
                <div className={"p-col-12"}>
                  <hr/>
                </div>
                <div className={"p-col-12 p-lg-6 p-xl-6"}>
                  <Field name="ntak_kategoria" render={({input, meta}) => (
                    <div className="p-field p-fluid">
                      <HelpComponent type={"field"} field={"ntak_kategoria"}/>
                      <label htmlFor="ntak_kategoria"
                             className={classNames({'p-error': isFormFieldValid(meta)})}>{t('ntak_kategoria')}</label>
                      <span className="">
                      <Dropdown options={ntakKategoria}
                                optionLabel="key"
                                optionValue="value"
                                filter
                                id={"ntak_kategoria"}
                                emptyMessage={t('empty_message')}
                                onChange={(e) => {
                                  setFormData({
                                    ...formData,
                                    ntak_kategoria: e.value
                                  })
                                }}
                                value={formData.ntak_kategoria}
                                showClear
                      />
                    </span>
                      {getFormErrorMessage(meta)}
                    </div>
                  )}/>
                </div>
              </>
              }
            </Panel>
          </form>
        )}/>
      </BlockUI>
      {locking === true &&
      <div className={"p-mt-2"}>
        <i className={"pi pi-exclamation-circle"}></i>
        &nbsp;
        {t('form_under_edit')}
        {formData && formData.under_edit_by_name_snap &&
        <>&nbsp;{t('form_under_edit_by')}: {formData.under_edit_by_name_snap}</>
        }
      </div>
      }
    </>
  )
}
export default withTranslation('common')(_FM_CategoryFormComponent);
