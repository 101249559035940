import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import axios from "axios"; import Cookies from "js-cookie";
import {OverlayPanel} from "primereact/overlaypanel";

import HelpComponent from "../HelpComponent";
import _FM_BerthFormComponent from "../_FM_berth/_FM_BerthFormComponent";

const _FM_UnitShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opb = useRef(null);

  useEffect(() => {
    if (window.App.state.propsUnit && window.App.state.propsUnit.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "residential-unit/" + window.App.state.propsUnit.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          window.App.setState({rerenderBerthList: false})
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.rerenderBerthList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('unit_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarUnitShow: false,
                        sidebarUnitForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="residential_category">{t('residential_category')}</label>
              <span className="p-d-block p-text-bold">
                {formData.residential_category?.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="number_of_single_beds">{t('number_of_single_beds')}</label>
              <span className="p-d-block p-text-bold">
                {formData.number_of_single_beds}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="number_of_double_beds">{t('number_of_double_beds')}</label>
              <span className="p-d-block p-text-bold">
                {formData.number_of_double_beds}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-4"}>
            <div className="p-field p-fluid">
              <label htmlFor="number_of_extra_beds">{t('number_of_extra_beds')}</label>
              <span className="p-d-block p-text-bold">
                {formData.number_of_extra_beds}
              </span>
            </div>
          </div>

          <div className={"p-col-12"}>
            <hr/>
          </div>
          <div className={"p-col-12"}>
            <label>{t('berths')}</label>
            <div className={"p-mt-2"}>
              {formData.berths?.map((item, index) => {
                return (
                  <div className={"p-grid"}>
                    <div className={"p-col-1 p-col-align-center p-text-bold"}>
                      {item.id}
                    </div>
                    <div className={"p-col-11 p-col-align-center p-text-bold"}>
                      {item.name}
                      &nbsp;
                      <i className={"pi pi-window-maximize p-ml-2"}
                         onClick={(e) => {
                           opb.current.toggle(e)
                           window.App.setState({
                             propsBerth: {id: item.id}
                           })
                         }}
                      ></i>
                      <OverlayPanel ref={opb} showCloseIcon>
                        <_FM_BerthFormComponent/>
                      </OverlayPanel>
                    </div>
                  </div>
                )
              })}
            </div>
          </div>

        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_FM_UnitShowComponent);
