import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import moment from "moment";

import HelpComponent from "../HelpComponent";
import {Button} from "primereact/button";

const _PC_CustomerShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsCustomer && window.App.state.propsCustomer.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "customer/" + window.App.state.propsCustomer.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('customer_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            {/*<Button type="button" aria-label={t('edit')}*/}
            {/*        icon={"pi pi-pencil"}*/}
            {/*        onClick={() => {*/}
            {/*          window.App.setState({*/}
            {/*            sidebarCustomerShow: false,*/}
            {/*            sidebarCustomerForm: true,*/}
            {/*          })*/}
            {/*        }}*/}
            {/*        className="p-button-warning p-ml-2">*/}
            {/*</Button>*/}
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="first_name">{t('first_name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.first_name} {formData.first_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="last_name">{t('last_name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.last_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="email">{t('email')}</label>
              <span className="p-d-block p-text-bold">
                {formData.email}
              </span>
            </div>
          </div>
          {formData.meta?.revolut?.customer &&
          <>
            <div className={"p-col-12"}>
              <hr/>
            </div>
            <div className={"p-col-12"}>
              <label>{t('revolut_customer')}</label>
              <div className={"p-mt-2"}>
                <div className={"p-grid"}>
                  <div className={"p-col-2 p-text-bold"}>
                    {formData.meta.revolut.customer.id}
                  </div>
                  <div className={"p-col-4 p-text-bold"}>
                    {formData.meta.revolut.customer.name}
                  </div>
                  <div className={"p-col-4 p-text-bold"}>
                    {formData.meta.revolut.customer.email}
                  </div>
                  <div className={"p-col-2 p-text-bold p-text-right"}>
                    {formData?.meta?.revolut?.customer?.id &&
                    <Button icon="pi pi-refresh" className="p-button-sm p-mr-2 p-button-secondary"
                            tooltip={t('refresh_revolut_customer')}
                            loading={loading}
                            tooltipOptions={{position: 'left'}}
                            onClick={() => {
                              setLoading(true)
                              axios({
                                method: 'get',
                                url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "customer/revolut/refresh/" + formData.id,
                                params: {},
                                headers: {
                                  'Authorization': 'Bearer ' + Cookies.get("userToken"),
                                  "Sys-name": Cookies.get("instanceId"),
                                  "Preferred-locale": Cookies.get("language")
                                }
                              })
                                .then(response => {
                                  setLoading(false)
                                  window.App.toastShow('success', t('success'), response.data.message)
                                })
                                .catch(error => {
                                  setLoading(false)
                                })

                            }}/>
                    }
                  </div>
                </div>
              </div>
            </div>
          </>
          }



        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(_PC_CustomerShowComponent);
