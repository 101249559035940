import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import moment from "moment";

import HelpComponent from "../HelpComponent";

const RegistrationsShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [currentSubscription, setCurrentSubscription] = useState([]);
  const [cancelledSubscription, setCancelledSubscription] = useState([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (window.App.state.propsRegistrations && window.App.state.propsRegistrations.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/user-registration/" + window.App.state.propsRegistrations.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          if(response.data.data.subscriptions && response.data.data.subscriptions[0]) {
            setCancelledSubscription(response.data.data.subscriptions.filter(item => item.status === 'CANCELLED'))
            setCurrentSubscription(response.data.data.subscriptions.filter(item => item.status !== 'CANCELLED'))
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('registrations_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="email">{t('email')}</label>
              <span className="p-d-block p-text-bold">
                {formData.email}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="system_name">{t('system_name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.system_name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="system_name_slugged">{t('system_name_slugged')}</label>
              <span className="p-d-block p-text-bold">
                {formData.system_name_slugged}
              </span>
            </div>
          </div>
        </div>
      </Panel>

      {currentSubscription && currentSubscription[0] &&
      <Panel className={"p-mt-3"}>
        <div className={"p-grid p-mb-5"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('active_subscriptionplan')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        {currentSubscription?.map((subscription, index) => {
          return (
            <div className={"p-grid"}>
              <div className={"p-col p-text-bold"}>
                {subscription.subscription_plan?.name}
              </div>
              <div className={"p-col p-text-bold"}>
                {subscription.status}
              </div>
              <div className={"p-col p-text-bold p-text-right"}>
                {moment.utc(subscription.subscription_start_date).local().format(t('YYYY-MM-DD'))}
                &nbsp;
                <i className={"pi pi-arrow-right"}></i>
                &nbsp;
                {moment.utc(subscription.next_payment_date).local().format(t('YYYY-MM-DD'))}
              </div>
            </div>
          )
        })}
      </Panel>
      }

      {cancelledSubscription && cancelledSubscription[0] &&
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('cancelled_subscriptionplan')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        {cancelledSubscription?.map((subscription, index) => {
          return (
            <div className={"p-grid p-mb-5"}>
              <div className={"p-col p-text-bold"}>
                {subscription.subscription_plan?.name}
              </div>
              <div className={"p-col p-text-bold"}>
                {subscription.status}
              </div>
              <div className={"p-col p-text-bold p-text-right"}>
                {moment.utc(subscription.subscription_start_date).local().format(t('YYYY-MM-DD'))}
                &nbsp;
                <i className={"pi pi-arrow-right"}></i>
                &nbsp;
                {moment.utc(subscription.next_payment_date).local().format(t('YYYY-MM-DD'))}
              </div>
            </div>
          )
        })}
      </Panel>
      }

    </>
  )
}
export default withTranslation('common')(RegistrationsShowComponent);
