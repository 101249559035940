import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _PC_AcceptingplaceListComponent from "../components/_PC_acceptingplace/_PC_AcceptingplaceListComponent";
import _PC_AcceptingplaceFormComponent from "../components/_PC_acceptingplace/_PC_AcceptingplaceFormComponent";
import _PC_AcceptingplaceShowComponent from "../components/_PC_acceptingplace/_PC_AcceptingplaceShowComponent";

const _PC_AcceptingplacePage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('acceptingplace') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_PC_AcceptingplacePage p-component p-m-lg-4">
      <Panel>
        <_PC_AcceptingplaceListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarAcceptingplaceForm} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarAcceptingplaceForm: false,
               })}>
        <_PC_AcceptingplaceFormComponent />
      </Sidebar>
      <Sidebar position="right" visible={window.App.state.sidebarAcceptingplaceShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarAcceptingplaceShow: false,
               })}>
        <_PC_AcceptingplaceShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_PC_AcceptingplacePage);
