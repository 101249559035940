import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Button} from "primereact/button";
import {OverlayPanel} from "primereact/overlaypanel";
import axios from "axios"; import Cookies from "js-cookie";
import HelpComponent from "../HelpComponent";
import OperatorFormComponent from "../operator/OperatorFormComponent";

const _PC_AcceptingplaceShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);
  const opo = useRef(null);

  useEffect(() => {
    if (window.App.state.propsAcceptingplace && window.App.state.propsAcceptingplace.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "accepting-place/" + window.App.state.propsAcceptingplace.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
          window.App.setState({rerenderOperatorList: false})

        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [window.App.state.rerenderOperatorList === true])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('acceptingplace_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>
            <Button type="button" aria-label={t('edit')}
                    icon={"pi pi-pencil"}
                    onClick={() => {
                      window.App.setState({
                        sidebarAcceptingplaceShow: false,
                        sidebarAcceptingplaceForm: true,
                      })
                    }}
                    className="p-button-warning p-ml-2">
            </Button>
          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-10"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="operator_data">{t('operator_data')}</label>
              <span className="p-d-block p-text-bold">
                {formData.operator_data?.company_name}
                &nbsp;
                <i className={"pi pi-window-maximize p-ml-2"}
                   onClick={(e) => {
                     opo.current.toggle(e)
                     window.App.setState({
                       propsOperator: {id: formData.operator_data?.id}
                     })
                   }}
                ></i>
                <OverlayPanel ref={opo} showCloseIcon>
                  <OperatorFormComponent/>
                </OverlayPanel>
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="revolut_integration">{t('revolut_integration')}</label>
              <span className="p-d-block p-text-bold">
                {formData.revolut_integration === true ? (
                  <i className={"pi pi-check text-success"}></i>
                ) : (
                  <i className={"pi pi-times text-danger"}></i>
                )}
              </span>
            </div>
          </div>
        </div>
      </Panel>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('api_user')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="email">{t('email')}</label>
              <span className="p-d-block p-text-bold">
                  {formData.api_user?.email}
                </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="password">{t('password')}</label>
              <span className="p-d-block p-text-bold">
                  {formData.api_user?.non_hashed_password}
                </span>
            </div>
          </div>
        </div>
      </Panel>
      {formData.revolut_integration && formData.revolut_integration === true &&
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('revolut_settings')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        <div className={"p-grid"}>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="merchant_api_readable_private_key">{t('merchant_api_private_key')}</label>
              <span className="p-d-block p-text-bold">
                    {formData.revolut_settings?.merchant_api_readable_private_key}
                  </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="webhook_url">{t('webhook_url')}</label>
              <span className="p-d-block p-text-bold">
                    {formData.revolut_settings?.webhook_url}
                  </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="webhook_id">{t('webhook_id')}</label>
              <span className="p-d-block p-text-bold">
                    {formData.revolut_settings?.webhook_id}
                  </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="signing_secret">{t('signing_secret')}</label>
              <span className="p-d-block p-text-bold">
                    {formData.revolut_settings?.signing_secret}
                  </span>
            </div>
          </div>
        </div>
      </Panel>
      }

    </>
  )
}
export default withTranslation('common')(_PC_AcceptingplaceShowComponent);
