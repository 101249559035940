import React, {useState, useEffect, useRef} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import {Sidebar} from "primereact/sidebar";

import _PC_CustomerListComponent from "../components/_PC_customer/_PC_CustomerListComponent";
import _PC_CustomerShowComponent from "../components/_PC_customer/_PC_CustomerShowComponent";

const _PC_CustomerPage = (props) => {

  const {t, i18n} = props;

  useEffect(() => {
    document.title = t('customer') + ' - ' + process.env.REACT_APP_TITLE;
    window.App.help()
  }, []);

  return (
    <div className="_PC_CustomerPage p-component p-m-lg-4">
      <Panel>
        <_PC_CustomerListComponent />
      </Panel>
      <Sidebar position="right" visible={window.App.state.sidebarCustomerShow} closeOnEscape={true}
               style={{width: '780px'}}
               onHide={() => window.App.setState({
                 sidebarCustomerShow: false,
               })}>
        <_PC_CustomerShowComponent />
      </Sidebar>
    </div>
  )
}
export default withTranslation('common')(_PC_CustomerPage);
