import React, {useState, useEffect} from 'react';
import {withTranslation} from 'react-i18next';
import {Panel} from "primereact/panel";
import axios from "axios"; import Cookies from "js-cookie";
import moment from "moment";

import HelpComponent from "../HelpComponent";

const SubscriptionplanShowComponent = (props) => {

  const {t} = props;
  const [formData, setFormData] = useState({});
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    console.log(window.App.state)
    if (window.App.state.propsSubscriptionplan && window.App.state.propsSubscriptionplan.id) {
      setLoading(true)
      axios({
        method: 'get',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "system-admin/subscription-plan/" + window.App.state.propsSubscriptionplan.id,
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          setLoading(false)
          setFormData(response.data.data);
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
          setLoading(false)
        })
    }
  }, [])

  return (
    <>
      <Panel className={"p-mt-3"}>
        <div className={"p-grid"}>
          <div className={"p-col"}>
            <h2 className={"p-m-0"}>{t('subscriptionplan_show')}</h2>
          </div>
          <div className={"p-col p-text-right p-col-align-center"}>

          </div>
        </div>
        <HelpComponent type={"site"} position={"IN_ACTIVE_BOX"}/>
        <div className={"p-grid"}>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="name">{t('name')}</label>
              <span className="p-d-block p-text-bold">
                {formData.name}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-5"}>
            <div className="p-field p-fluid">
              <label htmlFor="code">{t('code')}</label>
              <span className="p-d-block p-text-bold">
                {formData.code}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-2"}>
            <div className="p-field p-fluid">
              <label htmlFor="id">{t('id')}</label>
              <span className="p-d-block p-text-bold">
                {formData.id}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="amount">{t('amount')}</label>
              <span className="p-d-block p-text-bold">
                {formData.amount}
              </span>
            </div>
          </div>
          <div className={"p-col-12 p-lg-6"}>
            <div className="p-field p-fluid">
              <label htmlFor="currency">{t('currency')}</label>
              <span className="p-d-block p-text-bold">
                {formData.currency}
              </span>
            </div>
          </div>
          <div className={"p-col-12"}>
            <div className="p-field p-fluid">
              <label htmlFor="insider_description">{t('insider_description')}</label>
              <span className="p-d-block p-text-bold">
                {formData.insider_description}
              </span>
            </div>
          </div>
          {formData.subscriptions && formData.subscriptions[0] &&
          <>
            <div className={"p-col-12"}>
              <hr/>
            </div>
            <div className={"p-col-12"}>
              <label>{t('subscriptions')}</label>
              <div className={"p-mt-2"}>
                {formData.subscriptions?.map((subscription, index) => {
                  return (
                    <div className={"p-grid"}>
                      <div className={"p-col p-text-bold"}>
                        {subscription.id}
                      </div>
                      <div className={"p-col p-text-bold"}>
                        {subscription.status}
                      </div>
                    </div>
                  )
                })}
              </div>
            </div>
          </>
          }


        </div>
      </Panel>
    </>
  )
}
export default withTranslation('common')(SubscriptionplanShowComponent);
