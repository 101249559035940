import React, {Component, useRef} from 'react';
import {HashRouter as Router, Routes, Route, Link} from "react-router-dom";
import {withTranslation} from 'react-i18next';
import {BlockUI} from "primereact/blockui";
import {Toast} from 'primereact/toast';
import {Dialog} from "primereact/dialog";
import Favicon from 'react-favicon';
import i18next from 'i18next';
import axios from "axios";

import 'primereact/resources/primereact.min.css';
import 'primereact/resources/themes/bootstrap4-light-purple/theme.css';
import 'primeicons/primeicons.css';
import 'primeflex/primeflex.scss';
import './assets/css/app.css';

import NavbarComponent from "./components/NavbarComponent";

import LoginPage from "./pages/LoginPage";
import RegistrationPage from "./pages/RegisterPage";
import PasswordresetRequestPage from "./pages/PasswordresetRequestPage";
import PasswordresetSetPage from "./pages/PasswordresetSetPage";
import UsersPage from "./pages/UsersPage";
import ProfilePage from "./pages/ProfilePage";
import PartnerPage from "./pages/PartnerPage";
import HelpPage from "./pages/HelpPage";
import OperatorPage from "./pages/OperatorPage";
import MonologPage from "./pages/MonologPage";
import RegistrationsPage from "./pages/RegistrationsPage";
import SubscriptionplanPage from "./pages/SubscriptionplanPage";
import SubscriptionPage from "./pages/SubscriptionPage";
import _FM_ControlpanelPage from "./pages/_FM_ControlpanelPage";
import _FM_CalendarPage from "./pages/_FM_CalendarPage";
import _FM_AccommodationPage from "./pages/_FM_AccommodationPage";
import _FM_CategoryPage from "./pages/_FM_CategoryPage";
import _FM_UnitPage from "./pages/_FM_UnitPage";
import _FM_BerthPage from "./pages/_FM_BerthPage";
import _FM_BuilderPage from "./pages/_FM_BuilderPage";
import _FM_PaymentmethodPage from "./pages/_FM_PaymentmethodPage";
import _FM_ChanelPage from "./pages/_FM_ChanelPage";
import _FM_ServicePage from "./pages/_FM_ServicePage";
import _FM_OoounitPage from "./pages/_FM_OoounitPage";
import _FM_OooberthPage from "./pages/_FM_OooberthPage";
import _FM_ReservationPage from "./pages/_FM_ReservationPage";
import _NR_PointofsalePage from "./pages/_NR_PointofsalePage";
import _NR_MenuPage from "./pages/_NR_MenuPage";
import _NR_CategoryPage from "./pages/_NR_CategoryPage";
import _NR_DishPage from "./pages/_NR_DishPage";
import _NR_MenucomboPage from "./pages/_NR_MenucomboPage";
import _NR_OrderspotPage from "./pages/_NR_OrderspotPage";
import _NR_OrderPage from "./pages/_NR_OrderPage";
import _NR_PublicmenuPage from "./pages/_NR_PublicmenuPage";
import _NR_PublicorderPage from "./pages/_NR_PublicorderPage";
import _NR_ControlpanelPage from "./pages/_NR_ControlpanelPage";
import _PC_ControlpanelPage from "./pages/_PC_ControlpanelPage";
import _PC_AcceptingplacePage from "./pages/_PC_AcceptingplacePage";
import _PC_SubscriptionPage from "./pages/_PC_SubscriptionPage";
import _PC_RevolutsettingsPage from "./pages/_PC_RevolutsettingsPage";
import _PC_TransactionPage from "./pages/_PC_TransactionPage";
import _PC_CustomerPage from "./pages/_PC_CustomerPage";
import {Button} from "primereact/button";
import Cookies from "js-cookie";

if (process.env.REACT_APP_BRAND === 'felhomatrac') {
  require('primereact/resources/themes/saga-orange/theme.css');
  require('./assets/css/felhomatrac.css');
}
if (process.env.REACT_APP_BRAND === 'netrendel') {
  require('primereact/resources/themes/saga-blue/theme.css');
  require('./assets/css/netrendel.css');
}

class App extends Component {

  constructor(props) {

    super(props);
    this.toastShow = this.toastShow.bind(this);

    window.App = this;

    this.state = {}
  };

  componentDidMount() {
    if (Cookies.get('language') == null) {
      Cookies.set('language', 'hu', {
        expires: 365,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
      window.App.forceUpdate()
    }
    if (Cookies.get('language') == 'hu') {
      i18next.changeLanguage('hu')
    } else if (Cookies.get('language') == 'en') {
      i18next.changeLanguage('en')
    }
    document.getElementById('root').classList.add(process.env.REACT_APP_BRAND)
    if (localStorage.getItem('adminSession') === 'true' && Cookies.get('roles') &&
      Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN')) {
      document.getElementById('body').classList.add('admin')
    }
    if (Cookies.get('theme') === 'dark') {
      document.getElementById('body').classList.add('dark-theme')
      const themeLink = document.createElement('link');
      themeLink.rel = 'stylesheet';
      themeLink.href = '/dark.css';
      document.head.appendChild(themeLink);
    }
    if (!Cookies.get('theme') || Cookies.get('theme') === 'light') {
      document.getElementById('body').classList.remove('dark-theme')
      const link = document.querySelector(`link[href="/dark.css"]`);
      if (link) {
        link.parentNode.removeChild(link);
      }
    }
  }

  componentDidUpdate() {
  }

  toastShow(severity, summary, detail, life, response) {
    const {t} = this.props;
    if (response && response.data && response.data.code === 401) {
      this.logout()
    } else if (response && response.status && response.status === 404) {
      window.App.toastShow('warn', t('warning'), t('item_not_found'))
      if (response.data.toString().includes("No database available for given origin")) {
        this.logout()
      }
    } else if (response && response.status && response.status === 500) {
      window.App.toastShow('error', t('error'), t('error_500'))
    } else if (response && response.data && response.data.exception && response.data.exception.code === 404) {
      window.App.toastShow('warn', t('warning'), t('item_not_found'))
    } else {
      if (response && response.data && response.data.message && document.getElementById("toast").innerHTML
        && document.getElementById("toast").innerHTML.includes(response.data.message)) {
        //
      } else {
        if (response && response.data && response.data.data) {
          for (const key in response.data.data) {
            if (document.getElementById(key)) {
              if (document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error')) {
                document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error').remove()
              }
              document.getElementById(key).classList.add("p-invalid")
              document.getElementById(key).addEventListener('click', function handleClick() {
                document.getElementById(key).classList.remove("p-invalid");
                if (document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error')) {
                  document.getElementById(key).parentElement.parentElement.parentElement.querySelector('.p-error').remove()
                }
              });
              document.getElementById(key).parentElement.parentElement.insertAdjacentHTML("afterend", "<small class=\"p-error\">" + response.data.data[key] + "</small>")
            }
          }
        }
        window.App.toast.show({severity: severity, summary: summary, detail: detail, life: life});
      }
    }
  }

  changeLanguage(language) {
    if (language == 'hu') {
      Cookies.set('language', 'hu', {
        expires: 365,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
      i18next.changeLanguage('hu')
    } else if (language == 'en') {
      Cookies.set('language', 'en', {
        expires: 365,
        secure: JSON.parse(process.env.REACT_APP_APP_COOKIE_SECURE.toLowerCase()),
        sameSite: 'Strict',
        path: '/',
        domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
      });
      i18next.changeLanguage('en')
    }
  }

  urlVars() {
    var vars = {};
    var parts = window.location.href.replace(/[?&]+([^=&]+)=([^&]*)/gi, function (m, key, value) {
      vars[key] = value;
    });
    return vars;
  }

  transformQueryParams(lazyParams) {
    let query = '?'
    if (lazyParams.sortField) {
      query += '&sort=' + lazyParams.sortField.toLowerCase().replace(
        /[^a-zA-Z0-9]+(.)/g, (m, chr) => chr.toUpperCase())
    }
    if (lazyParams.sortOrder) {
      if (lazyParams.sortOrder == 1) {
        query += '&direction=asc'
      }
      if (lazyParams.sortOrder == -1) {
        query += '&direction=desc'
      }
    }
    // if (lazyParams.filters) {
    //   Object.entries(lazyParams.filters).map(function (filter, index) {
    //     query += '&search[' + filter[0].toLowerCase() + ']=' + filter[1].value
    //   })
    // }
    if (lazyParams.is_active === 1 || lazyParams.is_active === 0) {
      query += '&search[is_active]=' + lazyParams.is_active
    }
    if (lazyParams.search) {
      query += '&search=' + lazyParams.search
    }
    if (lazyParams.rows) {
      query += '&item_per_page=' + lazyParams.rows
    }
    if (lazyParams.page) {
      query += '&page=' + Number(lazyParams.page + 1)
    }
    return (query)
  }

  help() {
    const {t} = this.props;
    let _global = []
    let _site = []
    let _field = []
    if (window.location.href.split('/')[4] !== undefined) {
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "help-badge?url=/" + window.location.href.split('/')[4],
        data: {
          url: window.location.href.split('/')[4]
        },
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          if (response.data.data.items) {
            response.data.data.items.map((help, index) => {
              if (help.type === 'GLOBAL') {
                _global.push({
                  type: help.type,
                  position: help.position,
                  level: help.level,
                  message: help.message,
                  locale: help.locale,
                  url: help.url,
                })
              }
              if (help.type === 'ON_SITE') {
                _site.push({
                  type: help.type,
                  position: help.position,
                  level: help.level,
                  message: help.message,
                  locale: help.locale,
                  url: help.url,
                })
              }
              if (help.type === 'ON_FIELD') {
                _field.push({
                  type: help.type,
                  level: help.level,
                  field: help.form_field,
                  message: help.message,
                  locale: help.locale,
                  url: help.url,
                })
              }
            })
            window.App.setState({
              help: {
                global: _global,
                site: _site,
                field: _field
              }
            })
          }
        })
        .catch(error => {
          window.App.toastShow('error', t('error'), error.toString(), '', error.response);
        })
    }
  }

  formLock(entityClass, entityId) {
    window.App.setState({
      formLocked: entityClass,
      formEntityLocked: entityId
    })
    if (entityClass !== window.App.state.formLocked && entityId !== window.App.state.formEntityLocked) {
      //lock
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "lock",
        data: {
          entity_class: entityClass,
          entity_id: entityId
        },
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          //relock
          window.intervalUnLock = setInterval(() => {
            axios({
              method: 'post',
              url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "lock",
              data: {
                entity_class: entityClass,
                entity_id: entityId
              },
              params: {},
              headers: {
                'Authorization': 'Bearer ' + Cookies.get("userToken"),
                "Sys-name": Cookies.get("instanceId"),
                "Preferred-locale": Cookies.get("language")
              }
            })
              .then(response => {

              })
              .catch(error => {

              })
          }, 60000)
        })
        .catch(error => {

        })
    }
    clearInterval(window.intervalLock)
    window.intervalLock = setTimeout(function () {
      window.App.setState({
        formLocked: '',
        formEntityLocked: ''
      })
      //unlock
      axios({
        method: 'post',
        url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + "unlock",
        data: {
          entity_class: entityClass,
          entity_id: entityId
        },
        params: {},
        headers: {
          'Authorization': 'Bearer ' + Cookies.get("userToken"),
          "Sys-name": Cookies.get("instanceId"),
          "Preferred-locale": Cookies.get("language")
        }
      })
        .then(response => {
          clearInterval(window.intervalUnLock)

        })
        .catch(error => {

        })
    }, 1500)
  }

  delete(url, id) {
    return axios({
      method: 'delete',
      url: process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST + url + "/" + id,
      params: {},
      headers: {
        'Authorization': 'Bearer ' + Cookies.get("userToken"),
        "Sys-name": Cookies.get("instanceId"),
        "Preferred-locale": Cookies.get("language")
      }
    })
  }

  logout() {
    Cookies.remove('userToken', {
      path: '/',
      domain: process.env.REACT_APP_APP_COOKIE_DOMAIN
    });
    window.location.replace('/#/');
    window.App.forceUpdate()
  }

  render() {
    return (
      <Router>
        <div className={process.env.REACT_APP_BRAND} id="app">
          <Favicon url={require("." + process.env.REACT_APP_FAVICON_FILE)}/>
          <Toast ref={(el) => this.toast = el} position="bottom-right" id={"toast"}/>
          {Cookies.get("userToken") == null ? (
            <Routes>
              <Route path="*" element={<LoginPage/>} exact/>
              <Route path="/registration" element={<RegistrationPage/>}/>
              <Route path="/password-reset" element={<PasswordresetRequestPage/>}/>
              <Route path="/password-set" element={<PasswordresetSetPage/>}/>
              {process.env.REACT_APP_BRAND === 'netrendel' &&
              <Route path="/publicmenu" element={<_NR_PublicmenuPage/>} exact/>
              }
              {process.env.REACT_APP_BRAND === 'netrendel' &&
              <Route path="/publicorder" element={<_NR_PublicorderPage/>} exact/>
              }
            </Routes>
          ) : (
            <>
              <BlockUI blocked={this.state.loading}>
                <NavbarComponent/>
                <div id="page">
                  <Routes>
                    <Route path="/user" element={<UsersPage/>}/>
                    <Route path="/profile" element={<ProfilePage/>}/>
                    <Route path="/partner" element={<PartnerPage/>}/>
                    <Route path="/operator" element={<OperatorPage/>}/>
                    {Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_ADMIN') &&
                    <Route path="/help" element={<HelpPage/>}/>
                    }
                    {Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_ADMIN') &&
                    <Route path="/monolog" element={<MonologPage/>}/>
                    }
                    {Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_ADMIN') &&
                    <Route path="/registrations" element={<RegistrationsPage/>}/>
                    }
                    {Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_ADMIN') &&
                    <Route path="/subscriptionplan" element={<SubscriptionplanPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="*" element={<_FM_ControlpanelPage/>} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/control-panel" element={<_FM_ControlpanelPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/calendar" element={<_FM_CalendarPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/reservation" element={<_FM_ReservationPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/accommodation" element={<_FM_AccommodationPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/category" element={<_FM_CategoryPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/unit" element={<_FM_UnitPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/berth" element={<_FM_BerthPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/builder" element={<_FM_BuilderPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/payment-method" element={<_FM_PaymentmethodPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/chanel" element={<_FM_ChanelPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/service" element={<_FM_ServicePage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/ooounit" element={<_FM_OoounitPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'felhomatrac' &&
                    <Route path="/oooberth" element={<_FM_OooberthPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="*" element={<_NR_ControlpanelPage/>} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/control-panel" element={<_NR_ControlpanelPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/pointofsale" element={<_NR_PointofsalePage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/orderspot" element={<_NR_OrderspotPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/menu" element={<_NR_MenuPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/category" element={<_NR_CategoryPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/dish" element={<_NR_DishPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/menucombo" element={<_NR_MenucomboPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/order" element={<_NR_OrderPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/publicmenu" element={<_NR_PublicmenuPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'netrendel' &&
                    <Route path="/publicorder" element={<_NR_PublicorderPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="*" element={<_PC_ControlpanelPage/>} exact/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/control-panel" element={<_PC_ControlpanelPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/acceptplace" element={<_PC_AcceptingplacePage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/revolutsettings" element={<_PC_RevolutsettingsPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/subscription" element={<_PC_SubscriptionPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/transaction" element={<_PC_TransactionPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND === 'paycenter' &&
                    <Route path="/customer" element={<_PC_CustomerPage/>}/>
                    }
                    {process.env.REACT_APP_BRAND !== 'paycenter' &&
                    <Route path="/subscription" element={<SubscriptionPage/>}/>
                    }
                  </Routes>

                </div>
              </BlockUI>
              {(localStorage.getItem('adminSession') && localStorage.getItem('adminSession') === 'true')
              && Cookies.get('roles') && Cookies.get('roles').toString().includes('ROLE_SUPER_ADMIN') &&
              <>
                <Button type="button" className="p-button-danger" icon={"pi pi-code"} id={"debugButton"}
                        onClick={() => {
                          window.App.setState({debug: true})
                        }}/>
                <Dialog header="Debug box" visible={window.App.state.debug} position={"bottom"} style={{width: '50vw'}}
                        onHide={() => {
                          window.App.setState({debug: false})
                        }} footer={false} draggable={false} resizable={false}>
                  <div className={"p-grid"}>
                    <div className={"p-col-6"}>
                      <span className={"p-text-bold"}>
                        API Host:&nbsp;
                      </span>
                      {process.env.REACT_APP_API_PROTOCOL + process.env.REACT_APP_API_HOST}
                    </div>
                    <div className={"p-col-6"}>
                      <span className={"p-text-bold"}>
                        Node env:&nbsp;
                      </span>
                      {process.env.NODE_ENV}
                    </div>
                    <div className={"p-col-6"}>
                      <span className={"p-text-bold"}>
                        Brand:&nbsp;
                      </span>
                      {process.env.REACT_APP_BRAND}
                    </div>
                    <div className={"p-col-6"}>
                      <span className={"p-text-bold"}>
                        Frontend version:&nbsp;
                      </span>
                      {process.env.REACT_APP_VERSION}
                    </div>
                    <hr/>
                    <div className={"p-col-12"}>
                      <span className={"p-text-bold"}>
                        APP storage:&nbsp;
                      </span>
                      <pre>
                      {JSON.stringify(localStorage, null, "\t")}
                      </pre>
                    </div>
                  </div>
                </Dialog>
              </>
              }
            </>
          )}
        </div>
      </Router>
    );
  }
}

export default withTranslation('common')(App);
